import styled, { css } from 'styled-components';

import {
  ThemeProps as Prop, Components, Breakpoints,
  FontSize,
  FontFamily,
  TextTransformOptions,
  ColorProfiles,
} from '@powdr/constants';
import {
  componentColor,
  colorShade,
  getFontStyles,

} from '@powdr/utils';

const CPNT = Components.DOR_LIFTS_TRAILS;

export const StyledDorTable = styled.table`
  display: table;
  table-layout: fixed;
  border: none;
  border-collapse: collapse;
  border-collapse: separate;
  margin-bottom: 1px;
  background-color: transparent;
  width: 100%;

  tr {

    &.expanded-row {
      td {
        padding: 10px 0px;
      }
    }

    td {
      color: ${() => componentColor(Prop.CONTENT, CPNT)};
      text-align: center;
      margin: 0;
      padding: 8px 12px;

      .icon {
        margin: auto;
      }

      ${Breakpoints.mobile(css`
        ${() => getFontStyles(FontFamily.BASE_FONT, FontSize.REG20, undefined, undefined)};
        padding: 6px 6px;
        line-height: 15px;
      `)}

      .expanded-td {
       text-align: left;
       font-weight: normal;
       position: relative;
       display: flex;
       gap: 15px;
       padding: 10px 0px;
       isolation: isolate;

       &.col-2 {
        .content,
        .media {
          width: 50%;
        }
       }

       .expand-close-btn {
        align-self: self-start;
        margin-right: 15px;

        .icon svg {
          fill: ${() => componentColor(Prop.HEADER_ICON, CPNT, Prop.ICON)};
          color: ${() => componentColor(Prop.HEADER_ICON, CPNT, Prop.ICON)};
          width: 100%;
          height: 100%;
        }
       }

       .content {
        width: 100%;

        h3 {
          margin-bottom: 10px;
          color: ${() => componentColor(Prop.CONTENT, CPNT)};
        }
       }

       .media {
        display: flex;
        width: 100%;
        position: relative;

        img {
          aspect-ratio: 16/9;
          overflow: hidden;
          object-fit: cover;
        }
       }

       .btn {
          ${() => getFontStyles(FontFamily.SECONDARY_FONT, FontSize.REG20, undefined, undefined)};
          padding: 10px 12px;
          color: ${() => componentColor(Prop.BTN_TXT, CPNT)};
          background-color: ${() => componentColor(Prop.BTN_BG, CPNT)};
        }

        .btn:active {
          color: ${() => componentColor(Prop.BTN_TXT_ACTIVE, CPNT)};
          background-color: ${() => componentColor(Prop.BTN_BG_ACTIVE, CPNT)};
        }

        .btn:hover {
          color: ${() => componentColor(Prop.BTN_TXT_HOVER, CPNT)};
          background-color: ${() => componentColor(Prop.BTN_BG_HOVER, CPNT)};
        }

       p {
        line-height: 16px;
        margin-bottom: 5px;
       }
      }
      .expand-input-checkbox {
        position: relative;

        .icon {
          cursor: pointer;

          svg {
            fill: ${() => componentColor(Prop.HEADER_ICON, CPNT, Prop.ICON)};
            color: ${() => componentColor(Prop.HEADER_ICON, CPNT, Prop.ICON)};
            width: 100%;
            height: 100%;
          }
        }

        input {
          width: 1px;
          height: 1px;
          position: absolute;
          top: -100%;
          left: -100%;
          opacity: 0;
        }

      }

      &.groomed,
      &.features {
        svg,
        svg path {
          fill: ${() => componentColor(Prop.ICON, CPNT)};
          color: ${() => componentColor(Prop.ICON, CPNT)};
        }
      }
    }
  }

  tr {
    td.features {
      icon {
        display: inline-block;
        margin-right: 5px;
      }
    }

    td.details {
      padding: 10px;

      icon {
        margin: 0;
      }

      button {
        position: relative;
        display: inline-block;
      }
    }

    td.no-trails {
      text-align: left;
    }

    td.description {
      min-width: 30%;
      line-height: 16px;
    }

    td.notes {
      p {
        text-align: left;
      }
    }

    td.notes,
    td.description {
      p {
        ${() => getFontStyles(FontFamily.BASE_FONT, FontSize.REG20, undefined, undefined)};
        text-align: left;
      }

      span.default {
        display: inline-block;

        ${Breakpoints.mobile(css`
          display: none;
        `)}
      }

      button.more-info-btn {
        display: none;
        position: relative;
        width: 25px;
        height: 25px;

        ${Breakpoints.mobile(css`
          display: inline-block;
        `)}
      }
    }
  }

  thead {
    display: table-header-group;
    vertical-align: middle;

    th {
      padding: 8px 12px;
      white-space: nowrap;
      width: 100%;
      text-align: center;
      color: ${({ colorProfile }) => componentColor(Prop.TABLE, CPNT, Prop.table, colorProfile)};
      background-color: ${({ colorProfile }) => componentColor(Prop.TABLE_HEADER_BG, CPNT, Prop.TABLE_HEADER_BG, colorProfile)};
      z-index: 1;
      ${() => getFontStyles(FontFamily.PRIMARY_FONT, FontSize.REG50, undefined, undefined)};

      &.sticky {
        position: sticky;
        top: ${(props) => `${props.top}px`};
      }

      ${Breakpoints.mobile(css`
        ${() => getFontStyles(FontFamily.PRIMARY_FONT, FontSize.REG20, undefined, undefined)};
        padding: 6px 6px;
      `)}
    }

    th:first-child {
      text-align: left;
    }

    th:last-child {
      text-align: right;
    }
  }

  tbody {
    display: table-row-group;
    vertical-align: middle;

    td:first-child {
      text-align: left;
    }

    td:not(.no-trails):last-child {
      text-align: right;
    }
  }

  &.table-strip {
    tbody {
      tr:nth-child(odd) {
        background-color: ${() => componentColor(Prop.ODD_EVEN_OFFSET, CPNT, Prop.BACKGROUND)};
      }
    }
  }

  &.conditions.table-strip {
    tr:nth-child(odd) {
      background-color: ${() => colorShade(componentColor(Prop.HEADER_BG_2, CPNT, Prop.BACKGROUND), -0.1)};
    }
    tr:nth-child(even) {
      background-color: ${() => componentColor(Prop.HEADER_BG_2, CPNT, Prop.BACKGROUND)};
    }
  }

  &.conditions td {
    color: ${() => componentColor(Prop.HEADER_TXT_2, CPNT)};
  }
`;

export const StyledLiftsDetails = styled.ul`
  background-color: ${() => componentColor(Prop.HEADER_BG_2, CPNT, Prop.BACKGROUND)};
  color: ${() => componentColor(Prop.HEADER_TXT_2, CPNT, Prop.HEADER)};
  display: flex;
  padding: 10px;
  width: 100%;

  li.item {
    width: 33.333333%;
    margin-right: 10px;
    color: ${() => componentColor(Prop.HEADER_TXT_2, CPNT, Prop.HEADER)};
    strong {
      display: block;
    }
  }
`;

export const StyledLegendTrailTypeRating = styled.div`

  .ui-accordion-panel {
    z-index: 110;
  }

  .col {
    width: auto;

    ${Breakpoints.mobile(css`
      width: 100%;
    `)}

    .header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: start;
      background-color: transparent;
      margin-bottom: 20px;

      h4 {
        display: inline-block;
        margin-bottom: 0;
        margin-right: 5px;
        text-transform: uppercase;
      }
    }

    .content {
      display: flex;
      flex-direction: row;
      justify-content: start;
      margin-bottom: 15px;

      span.icon-wrap {
        margin: 5px;
        max-width: 20%;
        text-align: center;
      }

      p {
        margin-top: 5px;
        margin-bottom: 0;
        ${() => getFontStyles(FontFamily.BASE_FONT, FontSize.REG20, undefined, undefined)};
        line-height: 14px;
      }
    }
  }

`;

export const StyledDorSector = styled.div`

  &.sticky {

    .panel-header {
      position: sticky;
      top: ${(props) => `${props.top}px`};
    }

    .ui-accordion-panel.opened {
      position: relative;

      .panel-header {
        &:before {
          content: '';
          position: absolute;
          height: 100%;
          width: 100%;
          top: -100%;
          left: 0;
          bottom: 0;
          background-color: ${() => componentColor(Prop.BACKGROUND, CPNT)};
          display: block;
          z-index: auto;
        }
      }
    }
  }

  .ui-accordion-panel {
    padding-bottom: 15px;

    .panel-label {
      min-width: 30%;
    }

    .panel-body {
      background-color: ${() => componentColor(Prop.BACKGROUND, CPNT)};
    }

    .panel-render {
      display: flex;
      align-items: center;
      flex-flow: row-reverse;
    }

    .panel-details {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
    }

    .panel-header {
      background-color: ${() => componentColor(Prop.HEADER_BG, CPNT, Prop.BACKGROUND)};
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      z-index: 10;

      &.open-sector {
        .icon.expand {
          transition : all 0.35s ease-in-out;
          transform: rotate(0deg);
        }
      }

      .panel-icon {
        display: inline-block;
        padding: 10px;

        .icon svg {
          fill: ${() => componentColor(Prop.HEADER_ICON, CPNT, Prop.ICON)};
          color: ${() => componentColor(Prop.HEADER_ICON, CPNT, Prop.ICON)};
          width: 100%;
          height: 100%;
        }
      }

      &:focus {
        outline: 2px solid ${() => componentColor(Prop.HEADER_ICON, CPNT, Prop.ICON)};
        outline-offset: -4px;
      }
    }

    .panel-header.btn .panel-label {
      color: ${() => componentColor(Prop.HEADER_TXT, CPNT, Prop.HEADER)};
      ${() => getFontStyles(FontFamily.SECONDARY_FONT, FontSize.MED30, undefined, TextTransformOptions.CAPITALIZE)};
      margin: 0;

      ${Breakpoints.mobile(css`
        ${() => getFontStyles(FontFamily.SECONDARY_FONT, FontSize.MED10, undefined, TextTransformOptions.CAPITALIZE)};
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      `)}
    }
  }


`;

export const StyledDorFilter = styled.div`
  display: flex;
  padding: 20px;
  justify-content: space-between;

  ${Breakpoints.mobile(css`
    flex-direction: column;
    padding: 10px 0;
  `)}

  &.layout-row {
    align-items: center;

    ${Breakpoints.mobile(css`
      align-items: flex-start;
    `)}

    h4 {
      margin-bottom: 0;

      ${Breakpoints.mobile(css`
        margin-bottom: 15px;
      `)}

    }
  }

  &.layout-column {
    flex-direction: column;
  }

  &.layout-list {
    flex-direction: column;
    padding: 10px 0;

    .checkboxes {
      flex-direction: column;

      li {
        margin-bottom: 5px;
      }
    }
  }

  h4 {
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 15px;
  }


  .icon.extra-small,
  .icon.snowmaking-capable,
  .icon.snowmaking-24-hours,
  .icon.gladed,
  .icon.race {
    svg,
    svg path {
      width: 20px;
      fill: ${() => componentColor(Prop.ICON, CPNT)};
      color: ${() => componentColor(Prop.ICON, CPNT)};
    }
  }

  .icon.snowmaking-24-hours {
    svg {
      width: 18px;
      margin-left: 5px;
    }
  }

  .form {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .icon {
      margin-right: 8px;
    }
  }

  .lift-legend ul.checkboxes, .trail-legend ul.checkboxes {
    flex-direction: row;

    ${Breakpoints.mobile(css`
      row-gap: 7px;
      column-gap: 25px;
      flex-wrap: wrap;
    `)}
  }

  ul.checkboxes {
    display: flex;
    gap: 10px;
    flex-direction: column;
  }

  .difficulty {
    .level-black {
      margin-right: 0;
    }
    .level-black-2 {
      width: 32px;
    }
  }

`;

export const StyledSectorDetails = styled.ul`
  text-align: right;

  ${Breakpoints.mobile(css`
    display: none;
  `)}

  li.item {
    color: ${() => componentColor(Prop.HEADER_TXT, CPNT, Prop.HEADER)};
    display: inline-block;
    margin: 0;
    padding: 0;
    margin-right: 10px;
    text-transform: lowercase;

    strong {
      text-transform: capitalize;
      margin-right: 5px;
    }
  }
`;

export const StyledSectorDifficulty = styled.ul`
  background-color: ${() => componentColor(Prop.BACKGROUND, CPNT)};
  display: flex;
  margin: 0;
  padding: 0;
  line-height: 0;

  li {
    display: flex;
    padding: 5px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const StyledDorLiftsTrails = styled.div`
  margin-bottom: 30px;

  #sector-status-lifts-legend {
    z-index: 140;
    position: relative;
  }

  #sector-all-lifts-accordion.ui-accordion-panel {
    z-index: 135;
    position: relative;
  }

  #sector-status-trails-filters {
    position: relative;
    z-index: 125;
  }

  #sector-trail-types-rating-legend.ui-accordion-panel{
    z-index: 115;
  }

  #sector-trail-types-rating-filters{
    z-index: 110;
  }

  .slider .slick-slide button.play {
    left: 0;
  }

  h3 {
    color: ${() => componentColor(Prop.HEADER_TXT_2, CPNT, Prop.HEADER)};
    ${() => getFontStyles(FontFamily.PRIMARY_FONT, FontSize.MED20, undefined, undefined)};
  }

  h4 {
    ${() => getFontStyles(FontFamily.PRIMARY_FONT, FontSize.MED20, undefined, undefined)};
  }

  td.no-padding {
    padding: 0px;
    display: inline-block;
  }

  .icons {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: center;
    gap: 10px;

    .icon {
      margin-bottom: 5px;
    }
  }

  icon.opening,
  icon.closed,
  icon.on-hold,
  icon.expected {}

  icon.groomed-pm,
  icon.groomed-am,
  icon.groomed-recent {
    padding-top: 0;
    width: 35px;
    height: 18px;

    svg {
      width: 35px;
      height: 18px;
    }
  }

  button.tag-btn {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    margin: 5px;
    border: 1px solid;
    border-color: ${() => componentColor(Prop.BORDER, CPNT)};

    span {
      margin: 5px;
    }
  }

  .legend .status {
    icon.icon {
      margin-right: 8px;
    }
  }

  /* .lift-legend input {
    display: none;
  } */

  .legend {
    border-bottom: 1px solid;
    border-bottom-color: ${() => componentColor(Prop.BORDER, CPNT)};
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 22px;

    h3 {
      margin-bottom: 0;
    }

    .status {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      span {
        display: flex;
        margin: 18px;
        margin-right: 0;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .collapse-td {
    display: none;

    p {
      ${() => getFontStyles(FontFamily.BASE_FONT, FontSize.REG20, undefined, undefined)};
    }
  }

  .legend-trail-type-rating {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;

    .col {
      width: 33.3333%;
    }

    .header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: start;
      background-color: transparent;
      margin-bottom: 20px;

      h4 {
        display: inline-block;
        margin-bottom: 0;
        margin-right: 5px;
      }
    }

    .content {
      display: flex;
      flex-direction: row;
      justify-content: start;
      margin-bottom: 15px;

      span.icon-wrap {
        margin: 5px;
        max-width: 20%;
        text-align: center;
      }

      p {
        margin-top: 5px;
        margin-bottom: 0;
        ${() => getFontStyles(FontFamily.BASE_FONT, FontSize.REG20, undefined, undefined)};
        line-height: 14px;
      }
    }
  }

  .dor-lifts-trails-drawr,
  .dor-lifts-trails {
    .row-m-25 {
      margin-left: -25px;
      margin-right: -25px;
    }

    button.accordion-btn {
      ${() => getFontStyles(FontFamily.SECONDARY_FONT, FontSize.MED20, undefined, undefined)};

      a {
        margin-left: 5px;

        .icon-c svg {
          fill: ${() => componentColor(Prop.HEADER, CPNT)};
          color: ${() => componentColor(Prop.HEADER, CPNT)};
        }
      }
    }
  }

  .dor-lifts-trails-details {
    margin-top: 25px;
    margin-bottom: 25px;

    .right {
      padding-left: 22px;
    }
  }

  .left {
    text-align: left;
    padding-right: 22px;
  }

  strong {
    text-transform: uppercase;
  }

  p {
    display: block;
    margin-bottom: 15px;
  }

  p.short-description {
    display: none;
  }

  .level-black svg,
  .difficulty-level-black svg,
  .difficulty-level-black-2 svg,
  .difficulty-level-black-pill svg,
  .difficulty-level-black-2-pill svg,
  .level-black-2 svg {
    fill: black;
    color: black;
  }
`;

export const StyledLayoutFilters = styled.span`
  display: relative;

  width: 300px;

  .component-wrapper {
    padding: 26px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .icon {
    svg {
      filter: drop-shadow( 1px  0px 0px ${() => componentColor(Prop.WHITE, CPNT, Prop.BACKGROUND)})
              drop-shadow(-1px  0px 0px ${() => componentColor(Prop.WHITE, CPNT, Prop.BACKGROUND)})
              drop-shadow( 0px  1px 0px ${() => componentColor(Prop.WHITE, CPNT, Prop.BACKGROUND)})
              drop-shadow( 0px -1px 0px ${() => componentColor(Prop.WHITE, CPNT, Prop.BACKGROUND)});
    }
  }
`;

export const DorSticky = styled.div`
  z-index: 1;
  top: ${(props) => `${props.top}px`};
  position: sticky;
`;

export const StyledLayoutSectors = styled.div`
  display: ${({ $show }) => (($show) ? 'block' : 'none')};
  width: 100%;
  position: relative;
  z-index: 1;

  ${Breakpoints.mobile(css`
    width: 100%;
  `)}
`;

export const StyledLayoutWrapper = styled.div`
  display: flex;
  gap: 15px;

  ${Breakpoints.mobile(css`
    display: block;
  `)}
`;

export const StyledLayoutGrid = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 15px;
  padding: 20px;
`;

export const StyledDorLoaderWrapper = styled.div`
  display: ${({ $show }) => (($show) ? 'flex' : 'none')};
  position: relative;
  background-color: ${() => componentColor(Prop.BACKGROUND, CPNT)};
  z-index: 1;
  align-items: center;
  justify-content: center;
  width: 100%;
  ${() => getFontStyles(FontFamily.PRIMARY_FONT, FontSize.REG50, undefined, undefined)};
`;

export const LoaderContainer = styled.div`
  width: 100px;
  height: 100px;
`;

export const StyledDorErrorMessage = styled.div`
  position: relative;
  ${() => getFontStyles(FontFamily.PRIMARY_FONT, FontSize.REG50, undefined, undefined)};
  background-color: ${() => componentColor(Prop.BACKGROUND, CPNT)};
  z-index: 1;
  display: flex;
  padding: 10px;
  flex-direction: column;
  text-transform: uppercase;
  margin-left: auto;
  margin-right: auto;

  &::after {
    content: "${({ message }) => message}";
    margin: auto;
  }
`;

export const CheckboxGroupWrapper = styled.div``;

export const FilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  background-color: ${() => componentColor(Prop.BACKGROUND, CPNT, Prop.BACKGROUND, ColorProfiles.PRIMARY)};
  width: 100%;
  padding: 25px;
`;

export const FilterGroupTitle = styled.div`
  color: ${() => componentColor(Prop.HEADER_TXT, CPNT, Prop.HEADER)};
  ${() => getFontStyles(FontFamily.SECONDARY_FONT, FontSize.MED30, undefined)};
  margin: 0;

  ${Breakpoints.mobile(css`
    ${() => getFontStyles(FontFamily.SECONDARY_FONT, FontSize.MED40, undefined)};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `)}
`;

export const FilterNoResults = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  border: 1px solid ${() => componentColor(Prop.BORDER, CPNT)};
  width: 100%;
  padding: 15px;
  font-size: 12px;
  text-align: center;
  text-transform: capitalize;
`;
