import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { CalendarBadge } from '@powdr/components/calendar-badge';
import { Link } from '@powdr/components/link';
import { BlockTypes, Breakpoints, ThemeProps as Prop } from '@powdr/constants';
import { useWindowResize } from '@powdr/hooks';
import { ContentBlockType } from '@powdr/model';
import {
  themeUtil, getBackgroundImageSrc, clamp, rgbaTransparencyToHex,
} from '@powdr/utils';

const CPNT = BlockTypes.EVENT;

// styled components
const ContentBlockEvent = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  padding: 15px;
  min-height: 330px;
  background-image: ${({ bgImage }) => `url('${bgImage}')`};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  &:hover, &:focus {
    .cb-content {
      background-color: ${({ colorProfile }) => themeUtil(Prop.BACKGROUND, CPNT, colorProfile) + rgbaTransparencyToHex(1)};
    }
  }

  &:focus .button {
    background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG_HOVER, CPNT, colorProfile)};
    color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT_HOVER, CPNT, colorProfile)};
    outline: 2px solid ${({ colorProfile }) => themeUtil(Prop.BTN_BG_HOVER, CPNT, colorProfile)};
    outline-offset: 2px;
  }

  .cb-content {
    display: block;
    transition: .3s ease-in-out;
    text-align: center;
    width: 85%;
    padding: 25px 15px;
    background-color: ${({ colorProfile }) => themeUtil(Prop.BACKGROUND, CPNT, colorProfile) + rgbaTransparencyToHex(0.75)};

    ${Breakpoints.mobile(css`
      width: 100%;
    `)}

    .cb-title {
      margin-bottom: 10px;
    }

    .cb-copy {
      ${() => clamp(2)}
      margin-bottom: 15px;
    }

    .cb-title, .cb-copy {
      transition: .3s ease-in-out;
      margin-left: auto;
      margin-right: auto;
      max-width: 85%;

      ${Breakpoints.mobile(css`
        max-width: 100%;
      `)};
    }

    .button {
      display: inline-block;
    }

    .calendar-badge {
      margin-top: -70px;
      max-width: 110px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 10px;
    }
  }
`;

export const Event = ({
  title, copy, isOverlayEnabled, image, colorProfile, blockType, ariaLabel,
  buttonEnabled, buttonText, buttonLink, buttonTarget, buttonClick,
  date, endDate, columnOption, isStaticImageLink, inert,
}) => {
  const [bgImage, setBgImage] = useState('');
  const resize = useWindowResize();

  useEffect(() => {
    if (resize.windowWidth) {
      setBgImage(
        (!isStaticImageLink)
          ? getBackgroundImageSrc(image?.src, resize.windowWidth, columnOption)
          : image.src,
      );
    }
  }, [resize.windowWidth]);

  return (
    <ContentBlockEvent
      href={buttonLink}
      target={buttonTarget}
      bgImage={bgImage}
      colorProfile={colorProfile}
      onClick={() => buttonClick()}
      aria-label={ariaLabel}
      role="button"
      className={classNames(`content-block-${blockType} `, { overlay: isOverlayEnabled })}
      inert={inert}
    >
      <div className="cb-content">
        <CalendarBadge
          startDate={date}
          endDate={endDate}
          colorProfile={colorProfile}
        />
        {(title) && <h3 className="cb-title">{title}</h3>}
        {(copy) && <p className="cb-copy">{copy}</p>}
        {(buttonEnabled && buttonText) && (
          <div className="button  with-background" aria-label={ariaLabel}>
            {buttonText}
          </div>
        )}
      </div>
    </ContentBlockEvent>
  );
};

Event.propTypes = PropTypes.objectOf(ContentBlockType).isRequired;
