/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
import React from 'react';

import { ContentBlock } from '@powdr/components';
import { ContentBlockGroupLayout, BlockTypes } from '@powdr/constants';
import { useSeason } from '@powdr/hooks';
import { colorProfileByIndex } from '@powdr/utils';

import { StyledContentBlockGroup, LayoutWrapper } from './styles';

export const ContentBlockGroup = ({
  colorProfile,
  parentColorProfile,
  isPaddingOff,
  padding,
  layout,
  topPadding,
  bottomPadding,
  topPaddingMobile,
  bottomPaddingMobile,
  componentSeason,
  relationships,
}) => {
  const { checkSeasonality } = useSeason();
  const trueColorProfile = colorProfileByIndex(colorProfile) || parentColorProfile;

  const contentBlocks = relationships?.contentBlocks.reduce((accum, contentBlock) => {
    const { season, isHideBlock } = contentBlock;
    if (checkSeasonality(season) && !isHideBlock) accum.push(contentBlock);
    return accum;
  }, []);

  const checkLayout = () => {
    if (layout) return layout;
    if (contentBlocks.length % 2 === 0 || contentBlocks.length === 1) return ContentBlockGroupLayout.NO_LAYOUT;
    return ContentBlockGroupLayout.ONE_ON_LEFT;
  };

  const checkHeight = (index) => {
    if (
      ((checkLayout() === ContentBlockGroupLayout.ONE_ON_LEFT
        || checkLayout() === ContentBlockGroupLayout.ONE_ON_BOTTOM)
        && index === 0
      )
      || (checkLayout() === ContentBlockGroupLayout.ONE_ON_RIGHT && index === 2)
    ) {
      return true;
    }

    return false;
  };

  return (
    checkSeasonality(componentSeason) && (
      <StyledContentBlockGroup
        colorProfile={trueColorProfile}
        isPaddingOff={isPaddingOff}
        $topPadding={topPadding}
        $bottomPadding={bottomPadding}
        $topPaddingMobile={topPaddingMobile}
        $bottomPaddingMobile={bottomPaddingMobile}
      >
        <LayoutWrapper
          $layout={checkLayout()}
          $padding={padding || 10}

        >
          {contentBlocks.map((contentBlock, index) => (
            <ContentBlock
              contentBlock={contentBlock}
              index={index}
              key={contentBlock.id}
              className={`cbgroup-block-${index}`}
              isFullHeight={checkHeight(index)}
              blockType={contentBlock?.blockType || BlockTypes.FLOAT}
              colorProfile={(trueColorProfile !== null) ? trueColorProfile : colorProfileByIndex(contentBlock.colorProfile)}
              columnOption={2}
            />
          ))}
        </LayoutWrapper>
      </StyledContentBlockGroup>
    )
  );
};

ContentBlockGroup.propTypes = {
  colorProfile: PropTypes.number,
  parentColorProfile: PropTypes.string,
  isPaddingOff: PropTypes.bool,
  padding: PropTypes.number,
  topPadding: PropTypes.number,
  bottomPadding: PropTypes.number,
  topPaddingMobile: PropTypes.number,
  bottomPaddingMobile: PropTypes.number,
  layout: PropTypes.string,
  componentSeason: PropTypes.string,
  relationships: PropTypes.shape().isRequired,
};

ContentBlockGroup.defaultProps = {
  colorProfile: null,
  parentColorProfile: null,
  padding: 5,
  topPadding: 0,
  bottomPadding: 0,
  topPaddingMobile: 0,
  bottomPaddingMobile: 0,
  isPaddingOff: false,
  layout: ContentBlockGroupLayout.ONE_ON_LEFT,
  componentSeason: null,
};
