import styled, { css } from 'styled-components';

import { WysiwygStyleWrapper } from '@powdr/components';
import {
  ThemeProps as Prop, Breakpoints, Components, FontFamily, FontSize,
} from '@powdr/constants';
import { getFontStyles, themeUtil } from '@powdr/utils';

const CPNT = Components.FORM_SILVERPOP;

export const StyledFormSilverpop = styled(WysiwygStyleWrapper)`
  max-width: 50%;
  width: 100%;

  ${Breakpoints.mobile(css`
    max-width: 100%;
  `)}


  table {
    width: 100%;
    margin-bottom: 15px;
  }

  input[type="checkbox"]{
    transform: scale(1.5);
    padding: 10px;
  }

  .textInput {
    margin-bottom: 15px;
  }

  .checkbox_wrap {
    display: flex;
    align-items: baseline;
    padding-top: 15px;
  }

  .checkbox_label {
    margin-left: 10px;
  }

  .form-control {
    display: block;
    width: 100%;
    height: auto;
    padding: 10px 15px;
    ${() => getFontStyles(FontFamily.PRIMARY_FONT, FontSize.REG50, undefined, undefined)};
    line-height: 1.25;
    color: #55595c;
    background-color: white;
    background-image: none;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.15);
    margin-bottom: 15px;
  }

  .fieldLabel, .sectionHeader {
    ${() => getFontStyles(FontFamily.PRIMARY_FONT, FontSize.REG40, undefined, undefined)};
    padding-bottom: 10px;
    text-align: left;
  }

  p {
    text-align: left;
  }

  .form-button {
    border: none;
    padding: 15px 20px;
    color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT, CPNT, colorProfile)};
    background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG, CPNT, colorProfile)};

    &:hover {
      color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT_HOVER, CPNT, colorProfile)};
      background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG_HOVER, CPNT, colorProfile)};
    }
  }
`;
