import PropTypes from 'prop-types';
import React, { useEffect, useState, useRef } from 'react';

import { DropdownButton, MobileTabDrawer } from '@powdr/components';
import {
  ButtonText,
  AbsolutePositions,
  ColorProfiles,
  FilterDefaultState,
  trailFilter,
} from '@powdr/constants';
import { useIsInView } from '@powdr/hooks';
import { getArrayIntersection } from '@powdr/utils';

import { DorCheckboxGroup } from './dor-checkbox-group';
import {
  DataShape,
  SettingsShape,
} from './dor-prop-types';
import {
  CheckboxGroupWrapper,
  FilterGroupTitle, FilterWrapper, DorSticky, StyledLayoutFilters,
} from './styles';

export const DorLiftsTrailsFilters = ({
  data,
  settings,
  onFilterChange,
}) => {
  const productRef = useRef();
  const [isShowDrawer, setShowDrawer] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const isInView = useIsInView(productRef, 0, 0.01);

  const handleCheckboxClick = (e) => {
    onFilterChange(e, 'trail');
  };

  const handleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const checkboxGroups = () => trailFilter
    ?.map((grp) => (!settings.hideFilterDrawer
      ?.includes(grp.id) ? (
        <CheckboxGroupWrapper>
          <FilterGroupTitle>
            {grp.label}
          </FilterGroupTitle>
          <DorCheckboxGroup
            id={grp.id}
            layout="list"
            inputLabelTitle={grp.label}
            onFiltersChange={handleCheckboxClick}
            colorProfile={ColorProfiles.PRIMARY}
            checkInputsFields={
            (grp.fields[settings?.property] || grp.fields?.default)
              ?.filter((f) => {
                const inputIds = [
                  ...settings.hideTrailStatus || [],
                  ...settings.hideTrailDifficulty || [],
                  ...settings.hideTrailFeature || [],
                  ...settings.hideTrailFeatureDiff || [],
                  ...settings.hideTrailFeatureSize || [],
                  ...settings.hideTrailGroomedStatus || [],
                ];

                return f.alias.length > 0
                  ? getArrayIntersection(inputIds, f.alias)?.length === 0
                  : !inputIds.includes(f.id);
              })
            }
          />
        </CheckboxGroupWrapper>
      ) : null));

  useEffect(() => {
    if (isInView) {
      setShowDrawer(true);
    }
  }, [isInView]);

  return (
    <StyledLayoutFilters
      ref={productRef}
    >
      {(settings.isMobile && isShowDrawer) && (
        <MobileTabDrawer
          buttonText="FILTERS"
          drawerPosition={settings.drawerPlacement || AbsolutePositions.LEFT}
          isDrawerOpen={isDrawerOpen}
          handleDrawer={handleDrawer}
          buttonColorProfile={settings.filterToggleColorProfile || ColorProfiles.BASE}
          drawerColorProfile={settings.filterDrawerColorProfile || ColorProfiles.PRIMARY}
          sideOffset={settings.sectionMargin}
        >
          {settings.generalDrawr.FITLER_HEADER
            && data.conditionsData?.parkUpdate
            && (
              <div className="drawr-fitler-header">
                <h3>
                  Total Features:
                  {data.conditionsData?.parkUpdate[0]?.total_features || 0}
                </h3>
              </div>
            )}

          {checkboxGroups()}
        </MobileTabDrawer>
      )}
      {!settings.isMobile && (
        <DorSticky top={settings.drawerStickyOffset}>
          <DropdownButton
            closedButtonText={ButtonText.SHOW_FILTERS}
            openButtonText={ButtonText.HIDE_FILTERS}
            openButtonAriaLabel="Hide all search filters and close dropdown menu"
            closedButtonAriaLabel="Open dropdown menu and show all search filters"
            defaultState={(settings?.filterDefaultState === FilterDefaultState.OPEN)}
          >
            <FilterWrapper>
              {checkboxGroups()}
            </FilterWrapper>
          </DropdownButton>
        </DorSticky>
      )}
    </StyledLayoutFilters>
  );
};

DorLiftsTrailsFilters.propTypes = {
  data: DataShape.isRequired,
  settings: SettingsShape.isRequired,
  onFilterChange: PropTypes.func.isRequired,
};

DorLiftsTrailsFilters.defaultProps = {};
