import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

import {
  Breakpoints,
  ColorProfiles,
  Components, FontFamily, FontSize, ThemeProps as Prop,
} from '@powdr/constants';
import {
  componentColor, getFontStyles, rgbaTransparencyToHex, themeUtil,
} from '@powdr/utils';

const CPNT = Components.CONTENT_CAROUSEL;

const StyledFractionPagination = styled.div`
  display: flex;
  height: fit-content;
  padding: 15px;
  margin: 10px;
  align-items: center;
  justify-content: center;
  background-color: ${({ $colorProfile, $componentTheme }) => (($componentTheme) ? componentColor(Prop.ELEMENT, $componentTheme) : themeUtil(Prop.ELEMENT, CPNT, $colorProfile))}${rgbaTransparencyToHex(0.75)};
`;

const FractionPaginationWrapper = styled.div``;

const FractionPaginationInner = styled.div`
  color: ${({ $colorProfile, $componentTheme }) => (($componentTheme) ? componentColor(Prop.ELEMENT_TXT, $componentTheme) : themeUtil(Prop.ELEMENT_TXT, CPNT, $colorProfile))};
  ${() => getFontStyles(FontFamily.SECONDARY_FONT, FontSize.MED40, undefined, undefined)};
  line-height: 1.1;

  ${Breakpoints.mobile(css`
    ${() => getFontStyles(FontFamily.SECONDARY_FONT, FontSize.REG50, undefined, undefined)};
  `)}
`;

export const FractionPagination = ({
  className,
  numItems,
  activeIndex,
  colorProfile,
  componentTheme,
}) => (
  <StyledFractionPagination className={className}>
    <FractionPaginationWrapper $colorProfile={colorProfile} $componentTheme={componentTheme}>
      <FractionPaginationInner $colorProfile={colorProfile} $componentTheme={componentTheme}>
        {`${activeIndex + 1} / ${numItems}`}
      </FractionPaginationInner>
    </FractionPaginationWrapper>
  </StyledFractionPagination>
);

FractionPagination.propTypes = {
  className: PropTypes.string,
  numItems: PropTypes.number.isRequired,
  activeIndex: PropTypes.number.isRequired,
  colorProfile: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  componentTheme: PropTypes.oneOf(Object.values(Components)),
};

FractionPagination.defaultProps = {
  className: '',
  colorProfile: ColorProfiles.BASE,
  componentTheme: null,
};
