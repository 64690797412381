import styled, { css } from 'styled-components';

import { Button, Grid } from '@powdr/components';
import {
  ThemeProps as Prop, Components, Breakpoints,
  FontFamily,
  FontSize,
  Alignment,
} from '@powdr/constants';
import { getFontStyles, themeUtil } from '@powdr/utils';

const CPNT = Components.PRODUCT_FINDER;

export const StyledProductFinder = styled.div``;

export const Header = styled.header`
  margin-bottom: 25px;
  border-bottom: 1px solid ${({ $colorProfile }) => themeUtil(Prop.BORDER, CPNT, $colorProfile)};

  h3 {
    ${() => getFontStyles(FontFamily.PRIMARY_FONT, FontSize.LRG10, undefined, undefined)};
  }

  h3, p {
    margin-bottom: 15px;
  }
`;

export const ItemsContainer = styled.div``;

export const StyledLayoutMain = styled.div`
  flex: 1 1 auto;
  position: relative;
  z-index: 1;
`;

export const StyledLayoutAside = styled.div`
  ${({ $horizontalFilter, $stickyPosition }) => (
    (!$horizontalFilter)
      ? `flex: 0 0 225px; position: sticky; top: ${$stickyPosition}px; overflow-y: auto; height: calc(100vh - ${$stickyPosition}px - 20px);`
      : 'height: 100%;'
  )};
  margin-bottom: 20px;
`;

export const StyledGrid = styled(Grid)`
  margin: 0;
`;

export const FilterTags = styled.ul`
  display: flex;
  gap: 15px;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 15px;
`;

export const FilterTag = styled.li`
  button.btn {
    display: flex;
    align-items: center;
    padding: 10px;
    color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT, CPNT, colorProfile)};
    background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG, CPNT, colorProfile)};
    transition: color 0.25s ease-out, background-color 0.25s ease-out;

    &:hover, &:focus {
      transition: color 0.25s ease-out, background-color 0.25s ease-out;
      color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT_HOVER, CPNT, colorProfile)};
      background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG_HOVER, CPNT, colorProfile)};
    }

    &:active {
      color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT_ACTIVE, CPNT, colorProfile)};
      background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG_ACTIVE, CPNT, colorProfile)};
    }

    &:focus {
      outline: 2px solid ${({ colorProfile }) => themeUtil(Prop.BTN_BG_HOVER, CPNT, colorProfile)};
      outline-offset: 2px;
    }
  }

  .icon {
    margin-left: 5px;

    svg {
      fill: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT, CPNT, colorProfile)};
      color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT, CPNT, colorProfile)};
    }
  }
`;

export const StyledLayoutRow = styled.div`
  display: flex;
  flex-direction: ${({ $horizontalFilter }) => (($horizontalFilter) ? 'column' : 'row')};
  gap: 50px;

  ${Breakpoints.mobile(css`
    display: block;
  `)}
`;

export const StyledDrawer = styled.div`
  position: sticky;
  top: ${({ top }) => `${top}px`};
  z-index: 70;

  .ui-drawer.ui-drawer-enabled  {
    height: 100%;
    position: absolute;
    top: 0;
  }


  .ui-drawer {
    height: 100%;
    position: absolute;

    .drawer {
      padding: 0;
      background-color: ${({ $colorProfile }) => themeUtil(Prop.BACKGROUND, CPNT, $colorProfile)};

      button.fixed-open {
        position: absolute;
        right: 0;
        top: 50px;
        width: 130px;

        &.an-open {
          right: -130px;
        }
      }
    }
  }

  .ui-drawer-enabled {
    width: 310px;
  }

  .ui-drawer-content-wrap {
    background-color: ${({ $colorProfile }) => themeUtil(Prop.BACKGROUND, CPNT, $colorProfile)};
  }

  .ui-drawer-content {
    max-height: ${(props) => `calc(100vh - ${parseFloat(props.top) + 35}px)`} ;
    overflow-x: hidden;
    padding-top: 15px;
  }

  .ui-drawer-btn-toggle {
    background-color: ${({ $drawerButtonColor }) => themeUtil(Prop.BTN_BG, CPNT, $drawerButtonColor)};
    color: ${({ $drawerButtonColor }) => themeUtil(Prop.BTN_TXT, CPNT, $drawerButtonColor)};
    position: absolute;
    right: 100%;
    padding: 15px;
    transform-origin: bottom right;
    transform: rotate(-90deg);
    ${() => getFontStyles(FontFamily.SECONDARY_FONT, FontSize.MED30, undefined, undefined)};
    line-height: 30px;

    &:hover {
      color: ${({ $drawerButtonColor }) => themeUtil(Prop.BTN_TXT_HOVER, CPNT, $drawerButtonColor)} ;
      background-color: ${({ $drawerButtonColor }) => themeUtil(Prop.BTN_BG_HOVER, CPNT, $drawerButtonColor)};
    }

    &:active {
      background-color: ${({ $drawerButtonColor }) => themeUtil(Prop.BTN_TXT_ACTIVE, CPNT, $drawerButtonColor)};
      background-color: ${({ $drawerButtonColor }) => themeUtil(Prop.BTN_BG_ACTIVE, CPNT, $drawerButtonColor)};
    }
  }
`;

export const ClearFilterButton = styled(Button)`
  transition: color 0.25s ease-out, background-color 0.25s ease-out;
  margin-left: 25px;
`;
