import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { colorProfileByIndex } from '@powdr/utils';

import {
  StyledBookingWidget,
  StyledDesktopTabGroup,
} from './styles';
import { BookingWidgetTab } from './tab';
import { TabContent } from './tabContent';

export const BookingWidget = ({
  is5050,
  relationships,
  topPadding,
  bottomPadding,
  topPaddingMobile,
  bottomPaddingMobile,
  colorProfile,
  parentColorProfile,
}) => {
  const { tabs } = relationships;
  const [activeTab, setActiveTab] = useState(0);
  const trueColorProfile = (colorProfile) ? colorProfileByIndex(colorProfile) : parentColorProfile;

  return (
    <StyledBookingWidget
      className="booking-widget"
      colorProfile={trueColorProfile}
      $topPadding={topPadding}
      $bottomPadding={bottomPadding}
      $topPaddingMobile={topPaddingMobile}
      $bottomPaddingMobile={bottomPaddingMobile}
    >
      {/* Tabs for larger screens */}
      <StyledDesktopTabGroup justified isSingleTab={(tabs?.length === 1)}>
        {tabs
          .map((tab, index) => (
            <BookingWidgetTab
              key={tab.tabLabel}
              label={tab.tabLabel}
              colorProfile={trueColorProfile}
              className={classNames({ active: activeTab === index })}
              onClick={() => setActiveTab(index)}
              is5050={is5050}
            />
          ))}
      </StyledDesktopTabGroup>

      {/* Tabs for smaller screens */}
      {tabs
        .map((tab, index) => (
          <BookingWidgetTab
            mobile
            isSingleTab={(tabs?.length === 1)}
            key={tab.tabLabel}
            label={tab.tabLabel}
            colorProfile={trueColorProfile}
            className={classNames({ active: activeTab === index })}
            onClick={() => setActiveTab(index)}
          >
            <TabContent
              active={activeTab === index}
              is5050={is5050}
              tab={tab}
              colorProfile={trueColorProfile}
            />
          </BookingWidgetTab>
        ))}
    </StyledBookingWidget>
  );
};

BookingWidget.propTypes = {
  relationships: PropTypes.shape().isRequired,
  is5050: PropTypes.bool,
  colorProfile: PropTypes.string,
  topPadding: PropTypes.number,
  bottomPadding: PropTypes.number,
  topPaddingMobile: PropTypes.number,
  bottomPaddingMobile: PropTypes.number,
  parentColorProfile: PropTypes.string,
};

BookingWidget.defaultProps = {
  is5050: false,
  colorProfile: null,
  topPadding: 0,
  bottomPadding: 0,
  topPaddingMobile: 0,
  bottomPaddingMobile: 0,
  parentColorProfile: null,
};
