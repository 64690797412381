import { PropertyCMSPath } from '@powdr/constants/constants';
import { getDrupalDomain, getDrupalInlinePath } from '@powdr/utils';

// Check if object is empty
export const isEmpty = (obj) => Object.keys(obj || {}).length === 0;

// Use to check runtime vs buildtime, buildtime returns true
export const isBuildTime = () => typeof window === 'undefined';

// Use to valid email inputs
export const isValidEmail = (email) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/.test(email);

export const numberToDollar = (s) => ((s) ? s.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0.00');

// Fix links in ingested HTML dynamically based on property and environment
export const fixCmsLinks = (html) => ((!html)
  ? html
  : html.replaceAll(/src="\/sites\/([^/]+)(\/[^"]*)?"/g, (match, property, rest) => (
    (Object.values(PropertyCMSPath).includes(property))
      ? `src="${(property === 'default' ? `${getDrupalDomain(process.env.GATSBY_PROPERTY_NAME)}` : getDrupalDomain(getDrupalInlinePath(property)))}/sites/default${rest || ''}"`
      : match
  ))
);

// Filter incoming string to account for several weird text issues from Drupal data
export const textFilter = (s) => {
  // filterTable:
  // key is character you want
  // value is array of possible values that you want to replace with key
  const filterTable = {
    '&': ['&#038;', '&amp;'],
    "'": ['&#039;'],
    '"': ['&Quot;', '&quot;'],
  };
  let filteredString = s;

  if (filteredString) {
    Object.entries(filterTable).forEach(([replacement, valuesToCheck]) => {
      valuesToCheck.forEach((v) => {
        filteredString = filteredString.replaceAll(v, replacement);
      });
    });
  }

  return filteredString;
};

// Logic for handling whether to display whether a link should point to the nav item
// or if a link should point to it's first child, based on navigation settings
export const isLevelLinkNormal = (navItem) => (
  !!((navItem.childPages.filter((i) => !i.isHideFromNav)?.length === 0)
    || (navItem.childPages?.length === 1 && navItem.childPages[0].link.includes('/:item'))
    || (navItem.childPages?.length > 0 && navItem.isOpenChildrenInModals)
    || (navItem.childPages?.length > 0 && navItem.isShowBackBtnOnChildren)
  )
);

export const getLevelName = (level) => (
  level.navigationLinkTitle
  || level.title
  || level.name
  || level.buttonText);

export const isHideNavItem = (page, checkSeason) => !!((
  page?.isHideFromNav
    || !checkSeason(page?.season?.toLowerCase()))
);

// TODO: This can be removed entirely (as well as all usages)
// Some properties return the navigation link field in an array despite being a single value
export const getLevelLink = (level) => ((Array.isArray(level?.link))
  ? level.link[0]
  : level.link || level.buttonLink);

export const getLinkAtSetDepth = (levels, depth) => levels
  .map((_l, idx) => (idx < depth) && `/${levels[idx]}`)
  .filter((x) => x)
  .join('');

export const getActiveLinkAtSetDepth = (pages, levels, depth) => pages
  ?.find((p) => getLevelLink(p) === getLinkAtSetDepth(levels, depth));

// take an absolute path string and strip it down to a relative one
export const stripAbsolutePath = (s) => ((s?.includes('.com')) ? s?.substring(s.indexOf('.com') + 4) : s);

// take string and convert to slug, space = - and all lowercase
export const slugify = (s) => s?.toLowerCase()
  ?.replace(/_/g, '-')
  ?.replace(/ /g, '-')
  ?.replace(/[^\w-]+/g, '');

// take a slug string and turn it into a string with each word capitalized
// and spaces between
export const deslugify = (s) => s
  ?.replaceAll('_', ' ')
  ?.replaceAll('-', ' ')
  ?.split(' ')
  ?.map((sub) => sub.charAt(0).toUpperCase() + sub.substring(1))
  ?.join(' ');

export const camalize = (s) => s
  ?.toLowerCase()
  ?.replace(/[-_\s.]+(.)?/g, (m, chr) => chr
    ?.toUpperCase());

export const camalizeToTitle = (s) => textFilter(s)
  ?.replace(/([A-Z&]+)/g, ' $1')
  ?.replace(/([A-Z&][a-z&])/g, ' $1')
  ?.replace(/^./, (str) => str
    ?.toUpperCase());

export const getUniqueListBy = (arr, key) => [
  ...new Map(arr.map((item) => [item[key], item]))
    .values(),
];

export const capitalizeAllWords = (s) => s
  ?.split(' ')
  ?.map((word) => word[0].toUpperCase() + word.substring(1))
  ?.join(' ');

export const capitalizeFirstWord = (s) => s[0].toUpperCase() + s.substring(1);

export const groupByObjKey = (list, key, returnAs = {}) => list
  .reduce((rv, x) => {
    // eslint-disable-next-line no-param-reassign
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, returnAs);

export const getObValByStr = (obj, keys) => keys
  ?.split('.')
  ?.reduce((o, k) => (o || {})[k], obj);

export const getKeyByValue = (obj, value) => Object
  ?.entries(obj)
  .find(([, v]) => v === value)[0] || null;

export const range = (start, end) => Array
  .from({ length: end - start + 1 }, (_, idx) => idx + start);

// check if any value in one array exists in another
export const isArraysHaveSharedValue = (arr1, arr2) => arr1.some((r) => arr2.indexOf(r) >= 0);

export const getArrayIntersection = (a1, a2, ...r) => {
  const a12 = a1?.filter((v) => a2?.includes(v));
  if (r?.length === 0) { return a12; }
  return getArrayIntersection(a12, ...r);
};

export const flattenArray = (arr) => arr
  .reduce((acc, val) => acc
    .concat(Array.isArray(val)
      ? flattenArray(val)
      : val), []);

export const prettifyMachineName = (s) => s?.replace('field_', '')?.replace('&amp;', '&')?.replace('_', ' ');

export const getRatio = (a, b) => ((b === 0) ? a : getRatio(b, a % b));

export const mergeObjs = (objArr) => objArr.reduce(((r, c) => Object.assign(r, c)), {});

export const doubleDigitizer = (v) => ((v >= 1 && v <= 9) ? `0${v}` : v);

export const getRandomInt = (min, max) => {
  const minCeiled = Math.ceil(min);
  const maxFloored = Math.floor(max);
  return Math.floor(
    Math.random() * (maxFloored - minCeiled) + minCeiled,
  ); // The maximum is exclusive and the minimum is inclusive
};

export const phoneNumberPrettify = (s) => s?.replaceAll('-', '.');

export const checkIfNullUndefined = (d) => (d === null || d === undefined);
