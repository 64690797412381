import styled, { css } from 'styled-components';

import { ThemeProps as Prop, Breakpoints } from '@powdr/constants';
import { themeUtil } from '@powdr/utils';

export const StyledParallaxScroll = styled.div`
  #parallax1 {
    left: 0; /* First image from left */
  }

  .parallax-image-1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 35%; /* Cover full width */
    height: auto; /* Maintain aspect ratio */
    transform-origin: 0 0;
    overflow: hidden;
    perspective: 1px; /* Adds perspective to the parallax */
    z-index: -1; /* Send the image behind content */
  }

  .parallax-image-2 {
    position: absolute;
    top: 0;
    right: 0 !important;
    width: 35%; /* Cover full width */
    height: auto; /* Maintain aspect ratio */
    transform-origin: 0 0;
    overflow: hidden;
    perspective: 1px; /* Adds perspective to the parallax */
    z-index: -1; /* Send the image behind content */
  }
`;
