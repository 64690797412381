/* eslint-disable no-plusplus */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-unresolved */
import PropTypes from 'prop-types';
import React, {
  useRef, useState, useCallback, useEffect,
} from 'react';
import {
  Navigation, Autoplay,
  EffectFade, EffectCoverflow, EffectCards,
  Scrollbar, FreeMode, A11y, Thumbs, Parallax,
  Controller,
} from 'swiper/modules';

import {
  ColorProfiles, KeydownEventCodes,
  CarouselTransitionEffects, NavigationDirection,
  CarouselPaginationType,
  CarouselControlPlacement,
  handleCarouselNavigation,
  Components,
} from '@powdr/constants';

import { ControlArrow, ControlGroup } from './components';
import {
  StyledPrimaryCarousel, CarouselWrapper, StyledSwiper, StyledSwiperSlide,
  CarouselControlWrapper,
} from './styles';

const CarouselModuleMap = {
  [CarouselTransitionEffects.CARDS]: EffectCards,
  [CarouselTransitionEffects.COVERFLOW]: EffectCoverflow,
  [CarouselTransitionEffects.FADE]: EffectFade,
};

export const PrimaryCarousel = ({
  className: swiperClassName,
  isAutoRotate,
  isInfiniteScroll,
  controlSettings,
  transitionEffect,
  isAutoHeight,
  slidesPerView,
  slidesPerGroup,
  isCenteredSlides,
  spaceBetweenSlides,
  fitSlideWidthToItem,
  freeMode,
  parallax,
  isShowThumbnails,
  colorProfile,
  componentTheme,
  carouselToControl,
  breakpoints,
  children,
}) => {
  const ensureLoopConditions = () => {
    if (!children || children.length === 0) return [];

    // if showing preview slides using float (i.e. 1.5 slidesPerView),
    // double the amount of slides per view
    const adjustedSlidesPerView = (Number.isInteger(slidesPerView))
      ? slidesPerView
      : slidesPerView * 2;

    const totalSlides = children.filter((e) => e); // Filter non-falsy values
    const minSlides = (transitionEffect === CarouselTransitionEffects.COVERFLOW && isInfiniteScroll)
      ? 3
      : adjustedSlidesPerView + slidesPerGroup;

    let slidesToAdd = 0;

    // If carousel only needs to show 1 item
    if (children.length === 1 && adjustedSlidesPerView === 1 && slidesPerGroup === 1) {
      return totalSlides;
    }

    // Check if we need more slides to meet minimum conditions
    if (totalSlides.length < minSlides || totalSlides.length % slidesPerGroup !== 0) {
      slidesToAdd = minSlides - totalSlides.length;

      // Adjust slidesToAdd to ensure the total number of slides is divisible by slidesPerGroup
      if (totalSlides.length % slidesPerGroup !== 0) {
        slidesToAdd += slidesPerGroup - (totalSlides.length % slidesPerGroup);
      }

      // Clone elements
      const originalSlides = [...totalSlides]; // Store original slides to duplicate
      const clonedSlides = [];
      let i = 0;

      while (slidesToAdd > 0) {
        const element = originalSlides[i % originalSlides.length];
        const clonedElement = React.cloneElement(element, { key: `${element.key || i}-clone-${slidesToAdd}` });
        clonedSlides.push(clonedElement);
        slidesToAdd--;
        i++;
      }

      return [...totalSlides, ...clonedSlides];
    }

    return totalSlides;
  };
  const [carouselItems, setCarouselItems] = useState(ensureLoopConditions());
  const [activeIndex, setActiveIndex] = useState(0);
  const [thumbnailCarousel, setThumbnailCarousel] = useState(null);
  const [keyboardControlEnabled, setKeyboardControlEnabled] = useState(false);
  const [uniqueId] = useState(Math.random());
  const prevControlClass = controlSettings?.navigation?.classes?.[NavigationDirection.PREVIOUS] || `carousel-control-${NavigationDirection.PREVIOUS}-${uniqueId}`;
  const nextControlClass = controlSettings?.navigation?.classes?.[NavigationDirection.NEXT] || `carousel-control-${NavigationDirection.NEXT}-${uniqueId}`;
  const paginationClass = controlSettings?.pagination?.class || 'carousel-pagination';
  const controlsOnTop = !!((
    controlSettings?.navigation?.placement === CarouselControlPlacement.TOP
    || controlSettings?.pagination?.placement === CarouselControlPlacement.TOP
    || controlSettings?.scrollbar?.placement === CarouselControlPlacement.TOP
  ));
  const controlsOnBottom = !!((
    controlSettings?.navigation?.placement === CarouselControlPlacement.BOTTOM
    || controlSettings?.pagination?.placement === CarouselControlPlacement.BOTTOM
    || controlSettings?.scrollbar?.placement === CarouselControlPlacement.BOTTOM
  ));
  const controlsOnSlide = !!((
    controlSettings?.navigation?.placement === CarouselControlPlacement.ON_SLIDE
    || controlSettings?.pagination?.placement === CarouselControlPlacement.ON_SLIDE
  ));
  const controlsInWrapper = !!((
    controlSettings?.navigation?.placement === CarouselControlPlacement.IN_WRAPPER
    || controlSettings?.navigation?.placement === CarouselControlPlacement.IN_WRAPPER
  ));
  const paginationItems = (controlSettings?.pagination?.type === CarouselPaginationType.ICON_BULLETS
    || controlSettings?.pagination?.type === CarouselPaginationType.TEXT_BULLETS)
    ? controlSettings?.pagination?.customBullets
    : Array(carouselItems.length).fill({});
  const effectModule = CarouselModuleMap?.[transitionEffect] || null;
  const mainCarouselRef = useRef();
  const rotationSpeed = 7000; // ms
  const animationSpeed = 600; // ms

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { setCarouselItems(ensureLoopConditions()); }, [children]);

  const keyboardControl = useCallback((e) => {
    if (!keyboardControlEnabled || !mainCarouselRef?.current) return;

    if (e.keyCode === KeydownEventCodes.LEFT) {
      handleCarouselNavigation(NavigationDirection.PREVIOUS);
    }

    if (e.keyCode === KeydownEventCodes.RIGHT) {
      handleCarouselNavigation(NavigationDirection.NEXT);
    }
  }, [keyboardControlEnabled]);

  return (
    (carouselItems.length > 1) ? (
      <StyledPrimaryCarousel
        className={swiperClassName}
        $colorProfile={colorProfile}
        componentTheme={componentTheme}
        tabIndex={0}
        onFocus={() => setKeyboardControlEnabled(true)}
        onBlur={() => setKeyboardControlEnabled(false)}
        onKeyDown={(e) => keyboardControl(e)}
        aria-label="Use left and right arrow keys to navigate the carousel"
      >
        {/* Carousel Navigation Group */}
        {(controlsOnTop || controlsOnSlide) && (
          <ControlGroup
            showArrows={(controlSettings?.navigation?.placement === CarouselControlPlacement.TOP
              || controlSettings?.navigation?.placement === CarouselControlPlacement.ON_SLIDE)}
            showPagination={(controlSettings?.pagination?.placement === CarouselControlPlacement.TOP
              || controlSettings?.pagination?.placement === CarouselControlPlacement.ON_SLIDE)}
            showScrollbar={controlSettings?.scrollbar?.placement === CarouselControlPlacement.TOP
              || controlSettings?.scrollbar?.placement === CarouselControlPlacement.ON_SLIDE}
            placement={(controlsOnTop)
              ? CarouselControlPlacement.TOP
              : CarouselControlPlacement.ON_SLIDE}
            colorProfile={colorProfile || ColorProfiles.BASE}
            componentTheme={componentTheme}
            controlSettings={controlSettings}
            nextClass={nextControlClass}
            prevClass={prevControlClass}
            paginationClass={paginationClass}
            carouselRef={mainCarouselRef}
            items={paginationItems}
            activeIndex={activeIndex}
          />
        )}

        <CarouselControlWrapper className="carousel-control-wrapper">
          {/* In-Wrapper Navigation */}
          {(controlsInWrapper) && (
            <ControlArrow
              className={prevControlClass}
              carouselRef={mainCarouselRef}
              colorProfile={colorProfile || ColorProfiles.BASE}
              componentTheme={componentTheme}
              direction={NavigationDirection.PREVIOUS}
              iconName={controlSettings.navigation.iconName}
              iconSize={controlSettings.navigation.iconSize}
            />
          )}
          <CarouselWrapper className="carousel-wrapper">
            <StyledSwiper
              id={`unique-swiper-${uniqueId}`}
              modules={[Autoplay, Navigation, Scrollbar, FreeMode,
                A11y, Thumbs, Parallax, Controller]
                .concat((effectModule) ? [effectModule] : [])}
              onBeforeInit={(swiper) => { mainCarouselRef.current = swiper; }}
              onAfterInit={(swiper) => ((isInfiniteScroll) ? swiper.slideToLoop(0, 0) : null)}
              onSlideChange={(swiper) => { setActiveIndex(swiper.realIndex); }}
              autoplay={(isAutoRotate) ? {
                delay: rotationSpeed,
                disableOnInteraction: false,
              } : false}
              thumbs={(isShowThumbnails) && {
                autoScrollOffset: 1,
                swiper: thumbnailCarousel,
              }}
              freeMode={freeMode}
              autoHeight={isAutoHeight}
              loop={isInfiniteScroll}
              speed={animationSpeed}
              slidesPerView={slidesPerView}
              slidesPerGroup={slidesPerGroup}
              scrollbar={(controlSettings?.scrollbar?.placement) ? {
                enabled: true,
                draggable: true,
                el: '.scrollbar-element',
              } : false}
              navigation={{
                prevEl: `.${controlSettings?.navigation?.classes?.[NavigationDirection.PREVIOUS]}`,
                nextEl: `.${controlSettings?.navigation?.classes?.[NavigationDirection.NEXT]}`,
              }}
              effect={transitionEffect || null}
              centeredSlides={isCenteredSlides
              || transitionEffect === CarouselTransitionEffects.COVERFLOW}
              coverflowEffect={(transitionEffect === CarouselTransitionEffects.COVERFLOW)
                ? {
                  rotate: 50,
                  depth: 100,
                  modifier: 1,
                  slideShadows: false,
                } : null}
              spaceBetween={spaceBetweenSlides}
              controller={(carouselToControl) ? {
                control: carouselToControl,
              } : false}
              parallax={!!(parallax)}
              breakpoints={breakpoints}
            >
              {carouselItems
                .map((item, idx) => (
                  <StyledSwiperSlide
                    // eslint-disable-next-line react/no-array-index-key
                    key={`main-slide-${idx}-${uniqueId}`}
                    $fitSlideWidthToItem={fitSlideWidthToItem}
                    $slidesPerView={slidesPerView}
                    inert={Array
                      .from(
                        { length: slidesPerView },
                        (_, i) => ((mainCarouselRef?.current?.realIndex || 0) + i)
                        % (mainCarouselRef?.current?.slides?.length || 0),
                      )
                      .includes(idx)
                        || transitionEffect === CarouselTransitionEffects.COVERFLOW
                        || (slidesPerView === 'auto' && fitSlideWidthToItem)
                      ? null
                      : ''}
                  >
                    {item}
                  </StyledSwiperSlide>
                ))}
            </StyledSwiper>

            {/* Thumbnail Carousel */}
            {(isShowThumbnails) && (
            <StyledSwiper
              id={`unique-swiper-thumbnails-${uniqueId}`}
              onSwiper={(swiper) => setThumbnailCarousel(swiper)}
              modules={[Navigation, A11y, Thumbs]}
              speed={animationSpeed}
              slidesPerView={3}
              spaceBetween={10}
              $isThumbnailCarousel
            >
              {carouselItems
                .map((item) => React.cloneElement(item, { disabled: true }))
                .map((item, idx) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <StyledSwiperSlide key={`thumbnail-slide-${idx}-${uniqueId}`} $isThumbnail>
                    {item}
                  </StyledSwiperSlide>
                ))}
            </StyledSwiper>
            )}

          </CarouselWrapper>
          {/* In-Wrapper Navigation */}
          {(controlsInWrapper) && (
            <ControlArrow
              className={nextControlClass}
              carouselRef={mainCarouselRef}
              colorProfile={colorProfile || ColorProfiles.BASE}
              componentTheme={componentTheme}
              direction={NavigationDirection.NEXT}
              iconName={controlSettings.navigation.iconName}
              iconSize={controlSettings.navigation.iconSize}
            />
          )}
        </CarouselControlWrapper>

        {/* Carousel Navigation Group */}
        {(controlsOnBottom) && (
          <ControlGroup
            colorProfile={colorProfile || ColorProfiles.BASE}
            componentTheme={componentTheme}
            showArrows={(controlSettings?.navigation?.placement
              === CarouselControlPlacement.BOTTOM)}
            showPagination={(controlSettings?.pagination?.placement
              === CarouselControlPlacement.BOTTOM)}
            showScrollbar={(controlSettings?.scrollbar?.placement
              === CarouselControlPlacement.BOTTOM)}
            showDecoration={(controlSettings?.decoration?.placement
              === CarouselControlPlacement.BOTTOM)}
            placement={CarouselControlPlacement.BOTTOM}
            controlSettings={controlSettings}
            nextClass={nextControlClass}
            prevClass={prevControlClass}
            paginationClass={paginationClass}
            carouselRef={mainCarouselRef}
            items={paginationItems}
            activeIndex={activeIndex}
          />
        )}
      </StyledPrimaryCarousel>
    ) : carouselItems?.[0] || null
  );
};

PrimaryCarousel.propTypes = {
  className: PropTypes.string,
  controlSettings: PropTypes.shape({
    navigation: PropTypes.shape({
      placement: PropTypes.oneOf(Object.values(CarouselControlPlacement)),
      iconName: PropTypes.string,
      iconSize: PropTypes.number,
      classes: PropTypes.shape({
        next: PropTypes.string,
        prev: PropTypes.string,
      }),
    }),
    pagination: PropTypes.shape({
      placement: PropTypes.oneOf(Object.values(CarouselControlPlacement)),
      type: PropTypes.oneOf(Object.values(CarouselPaginationType)),
      class: PropTypes.string,
      customBullets: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      })),
    }),
    scrollbar: PropTypes.shape({
      placement: PropTypes.oneOf(Object.values(CarouselControlPlacement)),
    }),
    decoration: PropTypes.shape({
      placement: PropTypes.oneOf(Object.values(CarouselControlPlacement)),
    }),
  }),
  isAutoRotate: PropTypes.bool,
  isInfiniteScroll: PropTypes.bool,
  transitionEffect: PropTypes.oneOf(Object.values(CarouselTransitionEffects)),
  slidesPerView: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  slidesPerGroup: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isAutoHeight: PropTypes.bool,
  isCenteredSlides: PropTypes.bool,
  carouselToControl: PropTypes.shape({}),
  freeMode: PropTypes.bool,
  parallax: PropTypes.bool,
  spaceBetweenSlides: PropTypes.number,
  fitSlideWidthToItem: PropTypes.bool,
  isShowThumbnails: PropTypes.bool,
  breakpoints: PropTypes.shape({}),
  colorProfile: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  componentTheme: PropTypes.oneOf(Object.values(Components)),
  children: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

PrimaryCarousel.defaultProps = {
  className: '',
  controlSettings: {
    navigation: {
      placement: null,
      iconName: 'ui-arrow-left',
      iconSize: 25,
      classes: {
        [NavigationDirection.PREVIOUS]: `carousel-control-${NavigationDirection.PREVIOUS}`,
        [NavigationDirection.NEXT]: `carousel-control-${NavigationDirection.NEXT}`,
      },
    },
    pagination: {
      placement: null,
      type: null,
      class: 'carousel-pagination',
      customBullets: null,
    },
    scrollbar: {
      placement: null,
    },
    decoration: {
      placement: null,
    },
  },
  isAutoRotate: false,
  isInfiniteScroll: true,
  transitionEffect: null,
  isAutoHeight: false,
  isCenteredSlides: false,
  slidesPerView: 1,
  slidesPerGroup: 1,
  carouselToControl: null,
  freeMode: false,
  parallax: false,
  spaceBetweenSlides: 0,
  fitSlideWidthToItem: false,
  isShowThumbnails: false,
  breakpoints: null,
  colorProfile: null,
  componentTheme: null,
};
