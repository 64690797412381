import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

export const StyledSlideItem = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

export const SlideImageStatic = styled.img`
  display: block;
  width: 100%;
  height: 100%;
`;

export const SlideImageDynamic = styled(GatsbyImage)`
  display: block;
  width: 100%;
  height: 100%;
`;
