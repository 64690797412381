import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { useDispatch, useSelector } from 'react-redux';

import { CollapsePanel, CollapsePanelGroup } from '@powdr/components';
import { ColorProfiles } from '@powdr/constants';
import { getViewAll } from '@powdr/stores';

import {
  CampBlock, CampButton,
  CampLeftWrapper, CampList, CampListItem,
  CampSubheader, CampTitle, Caption, Photo,
  PhotoGrid, PhotoGridItem, PhotoGridTitle, PhotoGridWrapper,
  RatesDatesPanelInner, StyledRatesAndDatesGroup,
} from './styles';

export const RatesAndDatesGroup = ({ data }) => {
  const entityView = data?.entityView || null;
  const dispatch = useDispatch();
  const { drupal } = useSelector((state) => state.drupal) || [];
  const [viewData, setViewData] = useState([]);

  useEffect(() => {
    dispatch(getViewAll(entityView.viewMachineName));
  }, [dispatch, entityView.viewMachineName]);

  useEffect(() => {
    if (drupal?.[entityView?.viewMachineName]) {
      setViewData(drupal[entityView?.viewMachineName]);
    }
  }, [drupal, entityView.viewMachineName]);

  const createArray = (d) => ((Array.isArray(d)) ? d : [d]);

  if (!data || !entityView) return null;

  return (
    <StyledRatesAndDatesGroup>
      <CollapsePanelGroup parentColorProfile={ColorProfiles.BASE}>
        {viewData?.map((i, idx) => (
          <CollapsePanel
            panelColorProfile={ColorProfiles.QUATERNARY} // temporary
            cpHeader={{
              isContainer: false,
              title: i.field_display_title || i.title || null,
              subheader: i.field_subtitle || null,
            }}
            cpBody={{
              isContainer: false,
            }}
          >
            <RatesDatesPanelInner
              $colorProfile={ColorProfiles.QUATERNARY} // temporary
            >
              {(viewData[idx]?.field_camps) && (
                <CampList>
                  {createArray(viewData[idx]?.field_camps)?.map((camp) => (
                    <CampListItem>
                      <CampBlock>
                        <CampLeftWrapper>
                          <CampTitle>{camp?.field_title}</CampTitle>
                          {(camp?.field_formatted_subheader?.value) && (
                          <CampSubheader>
                            {ReactHtmlParser(camp?.field_formatted_subheader?.value)}
                          </CampSubheader>
                          )}
                        </CampLeftWrapper>
                        <CampButton
                          $colorProfile={ColorProfiles.QUATERNARY}
                          href={camp?.field_button?.field_btn_href}
                        >
                          {camp?.field_button?.field_btn}
                        </CampButton>
                      </CampBlock>
                    </CampListItem>
                  ))}
                </CampList>
              )}

              {/* Pros */}
              {((viewData?.[idx]?.field_pros)) && (
                <PhotoGridWrapper>
                  {(viewData?.[idx]?.field_pro_section_title) && (
                    <PhotoGridTitle>
                      {ReactHtmlParser(viewData[idx]?.field_pro_section_title)}
                    </PhotoGridTitle>
                  )}
                  <PhotoGrid $count={createArray(viewData[idx].field_pros.length)}>
                    {createArray(viewData[idx].field_pros)?.map((item) => (
                      <PhotoGridItem>
                        <Photo
                          src={item.field_referenced_image?.default}
                          alt={item.field_referenced_image?.alt}
                        />
                        <Caption>{item.field_title}</Caption>
                      </PhotoGridItem>
                    ))}
                  </PhotoGrid>
                </PhotoGridWrapper>
              )}

              {/* Sponsors */}
              {(viewData[idx]?.field_sponsors) && (
                <PhotoGridWrapper>
                  {(viewData[idx]?.field_sponsor_section_title) && (
                  <PhotoGridTitle>
                    {ReactHtmlParser(viewData[idx]?.field_sponsor_section_title)}
                  </PhotoGridTitle>
                  )}
                  <PhotoGrid $count={createArray(viewData[idx].field_sponsors.length)}>
                    {createArray(viewData[idx].field_sponsors)?.map((item) => (
                      <PhotoGridItem>
                        <Photo
                          src={item.field_referenced_image?.default}
                          alt={item.field_referenced_image?.alt}
                        />
                        <Caption>{item.field_title}</Caption>
                      </PhotoGridItem>
                    ))}
                  </PhotoGrid>
                </PhotoGridWrapper>
              )}
            </RatesDatesPanelInner>
          </CollapsePanel>
        ))}
      </CollapsePanelGroup>
    </StyledRatesAndDatesGroup>
  );
};

RatesAndDatesGroup.propTypes = {
  data: PropTypes.shape({
    entityView: PropTypes.shape({
      viewMachineName: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

RatesAndDatesGroup.defaultProps = {

};
