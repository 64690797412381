import styled, { css } from 'styled-components';

import {
  ThemeProps as Prop,
  Breakpoints,
  Components,
  AlertType,
  StaticProfiles,
  GlobalLayoutValues,
  AlertColorMap,
} from '@powdr/constants';
import { componentColor } from '@powdr/utils';

const extraTrayHeight = 50; // px

const CPNT = Components.SIDEBAR_TRAY;

const getColor = (component, colorOverride, prop) => {
  if (component?.typeName === Components.DOR_MINI_ALERT) {
    return componentColor(
      (colorOverride)
        ? AlertColorMap[colorOverride][prop]
        : AlertColorMap[AlertType.INFO][prop],
      CPNT,
      prop,
      (colorOverride !== AlertType.INFO && colorOverride) && StaticProfiles.ALERTS,
    );
  }

  return componentColor(prop, CPNT);
};

export const StyledSidebarTray = styled.div``;

export const SidebarTrayAnimationWrapper = styled.div`
  overflow: hidden;

  ${Breakpoints.desktopOnly(css`
    position: absolute;
    height: auto;
    width: 35vw;
    max-width: 675px;
    top: -${extraTrayHeight / 2}px;
    right: ${({ $offsetWidth }) => $offsetWidth}px;
    box-shadow: ${({ $isOpen }) => (($isOpen) ? '-3px -4px 40px 0px darkgrey' : 'none')};
    transform: ${({ $isOpen }) => (($isOpen) ? 'scaleX(1)' : 'scaleX(0)')};
    transform-origin: right;
    transition:
      ${({ $isOpen }) => ($isOpen) && `box-shadow ${GlobalLayoutValues.ALL_PLATFORM.WRAPPER.ANIMATION_DURATION}ms 100ms ease-in-out,`}
      width ${GlobalLayoutValues.ALL_PLATFORM.WRAPPER.ANIMATION_DURATION}ms ease-in-out,
      transform ${GlobalLayoutValues.ALL_PLATFORM.WRAPPER.ANIMATION_DURATION}ms ease-in-out;
  `)}

  ${Breakpoints.mobile(css`
    width: 100%;
    height: auto;
    bottom: ${({ $sidebarHeight }) => $sidebarHeight}px;
    position: absolute;
    transform: ${({ $isOpen }) => (($isOpen) ? 'scaleY(1)' : 'scaleY(0)')};
    transform-origin: bottom;
    transition: transform ${GlobalLayoutValues.ALL_PLATFORM.WRAPPER.ANIMATION_DURATION}ms ease-in-out;
  `)}
`;

export const SidebarTrayInner = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  color: ${({ $component, $trayColorOverride }) => getColor($component, $trayColorOverride, Prop.CONTENT)};
  background-color: ${({ $component, $trayColorOverride }) => getColor($component, $trayColorOverride, Prop.BACKGROUND)};

  .component-wrapper * {
    ${({ $component, $trayColorOverride }) => ($component?.typeName === Components.DOR_MINI_ALERT) && `color: ${getColor($component, $trayColorOverride, Prop.CONTENT)};`}
  }

  ${Breakpoints.mobile(css`
    flex-direction: column;
  `)}
`;

const sharedCloseButtonStyles = css`
  height: 100%;
  background-color: ${({ $component, $trayColorOverride }) => getColor($component, $trayColorOverride, Prop.BACKGROUND)};

  svg {
    fill: ${({ $component, $trayColorOverride }) => getColor($component, $trayColorOverride, Prop.CONTENT)};
    color: ${({ $component, $trayColorOverride }) => getColor($component, $trayColorOverride, Prop.CONTENT)};
  }

  &:focus svg {
    outline: 2px solid ${({ $component, $trayColorOverride }) => getColor($component, $trayColorOverride, Prop.CONTENT)};
    outline-offset: 0.2em;
  }
`;

export const CloseButtonDesktop = styled.button`
  ${sharedCloseButtonStyles}
  padding: 0 15px;

  ${Breakpoints.mobile(css`
    display: none;
  `)}
`;

export const CloseButtonMobile = styled.button`
  ${sharedCloseButtonStyles}
  padding: 8px;

  ${Breakpoints.desktopOnly(css`
    display: none;
  `)}
`;

export const ComponentWrapper = styled.div`
  width: 100%;
  height: 100%;

  ${Breakpoints.mobile(css`
    padding: 0 15px;
  `)}
`;

const trayDecorationSize = 55; // px

export const TrayDecoration = styled.div`
  flex: 0 0 auto;
  //position: relative;
  height: 100%;
  width: ${trayDecorationSize}px;

  &:after {
    content: " ";
    position: absolute;
    //top: calc(50% - ${trayDecorationSize}px);
    top: 0;
    right: 0;
    border: solid ${({ $component, $trayColorOverride }) => getColor($component, $trayColorOverride, Prop.CONTENT)};
    border-width: ${trayDecorationSize}px;
    border-top: ${trayDecorationSize - 2}px solid transparent;
    border-bottom: ${trayDecorationSize}px solid transparent;
    border-left: none;
    width: 0;
    height: 0;
    z-index: 2;
  }

  ${Breakpoints.mobile(css`
    display: none;
  `)}
`;
