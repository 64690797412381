import { GatsbyImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import {
  Icon, IconDetails, Link,
} from '@powdr/components';
import {
  BlockTypes, ColorProfiles, FontFamily, ThemeProps as Prop,
} from '@powdr/constants';
import { ContentBlockType } from '@powdr/model';
import {
  themeUtil,
  getFontStyles,
  handleApiObjArr,
} from '@powdr/utils';

const CPNT = BlockTypes.INFO;

// styled components
const ContentBlockInfo = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto auto 160px;
  gap: 10px;
`;

const ImageBlock = styled.div`
  grid-column: span 2 / span 2;
`;

const TitleBlock = styled.div`
  grid-column: span 2 / span 2;
  grid-row-start: 2;
  padding: 10px 0;
  text-align: center;
  background-color: ${({ $colorProfile }) => themeUtil(Prop.BACKGROUND, CPNT, $colorProfile)};
  color: ${({ $colorProfile }) => themeUtil(Prop.HEADER, CPNT, $colorProfile)};
`;

const DetailBlock = styled.div`
  padding: 15px;
  grid-row-start: 3;
  background-color: ${({ $colorProfile }) => themeUtil(Prop.BACKGROUND, CPNT, $colorProfile)};
`;

const InnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 100%;

  > div {
    width: 100%;
  }
`;

const DetailBlockLabel = styled.div`
  ${() => getFontStyles(FontFamily.PRIMARY_FONT, '16px')};
  color: ${({ $colorProfile }) => themeUtil(Prop.HEADER, CPNT, $colorProfile)};
`;

const DetailBlockComponent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

const ButtonBlock = styled(Link)`
  padding: 15px;
  grid-row-start: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ $colorProfile }) => themeUtil(Prop.BACKGROUND, CPNT, $colorProfile)};
`;

const ButtonBlockText = styled.div`
  padding: 5px 12px;
  ${() => getFontStyles(FontFamily.SECONDARY_FONT, '16px')};
  background-color: ${({ $colorProfile }) => themeUtil(Prop.BTN_BG, CPNT, $colorProfile)};
  color: ${({ $colorProfile }) => themeUtil(Prop.BTN_TXT, CPNT, $colorProfile)};
`;

const Image = styled.img`
  display: block;
`;

export const Info = ({
  title, image, blockType, ariaLabel,
  buttonText, buttonLink, buttonTarget,
  iconName, isStaticImageLink, detailComponent,
}) => (
  <ContentBlockInfo
    className={`content-block-${blockType} `}
  >
    <ImageBlock $icon={!!(iconName)} $image={!!(image)}>
      {(image && !isStaticImageLink) && <GatsbyImage image={image.src} alt={image?.altText} />}
      {(image && isStaticImageLink) && <Image src={image.src} alt={image?.altText} />}
      {(!image && iconName) && <Icon className="cb-icon" name={iconName} />}
    </ImageBlock>
    <TitleBlock $colorProfile={ColorProfiles.PRIMARY}>
      <h3 className="cb-title">{title}</h3>
    </TitleBlock>
    <DetailBlock $colorProfile={ColorProfiles.SECONDARY}>
      {(detailComponent?.label) && (
      <DetailBlockLabel $colorProfile={ColorProfiles.SECONDARY}>
        {detailComponent.label}
      </DetailBlockLabel>
      )}
      {(detailComponent?.data) && (
        <DetailBlockComponent>
          <InnerWrapper>
            <IconDetails
              iconData={handleApiObjArr(detailComponent?.data?.field_icons)
                ?.reduce((icons, icon) => {
                  icons.push({
                    iconName: icon?.field_icon_name,
                    title: icon?.field_title,
                  });
                  return icons;
                }, [])}
              iconColor={themeUtil(Prop.HEADER, CPNT, ColorProfiles.SECONDARY)}
              textColor={themeUtil(Prop.CONTENT, CPNT, ColorProfiles.SECONDARY)}
              hyphenateItems={detailComponent?.data?.field_hyphenate_items === '1'}
            />
          </InnerWrapper>
        </DetailBlockComponent>
      )}
    </DetailBlock>
    <ButtonBlock
      href={buttonLink}
      target={buttonTarget}
      aria-label={ariaLabel}
      role="button"
      $colorProfile={ColorProfiles.SECONDARY}
    >
      <ButtonBlockText $colorProfile={ColorProfiles.SECONDARY}>{buttonText}</ButtonBlockText>
    </ButtonBlock>
  </ContentBlockInfo>
);

Info.propTypes = PropTypes.objectOf(ContentBlockType).isRequired;
