// eslint-disable-next-line import/no-unresolved
import { navigate } from '@reach/router';
import PropTypes from 'prop-types';
import React from 'react';

import { Icon, Link } from '@powdr/components';
import {
  isBuildTime,
} from '@powdr/utils';

import {
  PageBackButton,
} from './styles';

export const BackButton = ({
  backButtonLink,
}) => {
  if (isBuildTime()) return null;

  // Using this to decide whether to navigate to prev history or form parent page link below.
  const isUseHistory = (window?.history?.state || !backButtonLink) || false;

  return (
    <PageBackButton className="levels-back-button">
      { isUseHistory
        ? (
          <button type="button" onClick={() => navigate(-1)}>
            Back
            <Icon className="levels-back-icon" name="ui-back-arrow" width="20" />
          </button>
        ) : (
          <Link
            aria-label="Back"
            href={backButtonLink}
          >
            <button type="button">
              Back
              <Icon className="levels-back-icon" name="ui-back-arrow" width="20" />
            </button>
          </Link>
        )}
    </PageBackButton>
  );
};

BackButton.propTypes = {
  backButtonLink: PropTypes.string,
};

BackButton.defaultProps = {
  backButtonLink: null,
};
