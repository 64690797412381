import { GatsbyImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import styled, { css } from 'styled-components';

import { WysiwygStyleWrapper } from '@powdr/components';
import { Icon } from '@powdr/components/icon';
import { Link } from '@powdr/components/link';
import {
  AlternatingBlockLayoutOptions, BlockTypes,
  Breakpoints, FontFamily, ThemeProps as Prop,
} from '@powdr/constants';
import { ContentBlockType } from '@powdr/model';
import { themeUtil, clamp, getFontStyles } from '@powdr/utils';

const CPNT = BlockTypes.CARD_BANNER_ALT;

// styled components
const ContentBlockCardBannerAlt = styled.div`
  display: flex;
  flex-direction: ${({ alternateBlockLayoutState }) => (
    (alternateBlockLayoutState === AlternatingBlockLayoutOptions.DEFAULT)
      ? 'row'
      : 'row-reverse'
  )};
  justify-content: center;
  box-shadow: 0 0 4px 4px rgba(41, 45, 49, 0.1), 0 0 4px 2px rgba(41, 45, 49, 0.1);
  margin: 5px;
  background-color: ${({ colorProfile }) => themeUtil(Prop.BACKGROUND, CPNT, colorProfile)};

  .video-icon {
    position: absolute;
    top: calc(50% - 50px);
    left: calc(50% - 50px);
    fill: ${({ colorProfile }) => themeUtil(Prop.ICON, CPNT, colorProfile)};
    color: ${({ colorProfile }) => themeUtil(Prop.ICON, CPNT, colorProfile)};
  }

  ${Breakpoints.mobile(css`
    flex-direction: column;

    .link-icon {
      display: none;
    }
  `)}
`;

const ImageContainer = styled.button`
  width: 50%;
  height: auto;
  padding: 0;

  ${Breakpoints.mobile(css`
    width: 100%;
  `)};
`;

const ContentContainer = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  width: 50%;
  height: auto;
  background-color: ${({ colorProfile }) => themeUtil(Prop.BACKGROUND, CPNT, colorProfile)};
  padding: 40px;

  ${Breakpoints.mobile(css`
    align-items: center;
    justify-content: space-between;
    width: 100%;
    text-align: center;
    min-height: 250px;
  `)}

  p {
    ${() => clamp(6)}
  }

  &:hover, &:focus {
    .card-banner-alt-button {
      color: ${({ colorProfile }) => themeUtil(Prop.LINK_TXT_HOVER, CPNT, colorProfile)};

      .link-icon {
        svg {
          fill: ${({ colorProfile }) => themeUtil(Prop.LINK_TXT_HOVER, CPNT, colorProfile)};
          color: ${({ colorProfile }) => themeUtil(Prop.LINK_TXT_HOVER, CPNT, colorProfile)};
        }
      }
    }
  }

  &:focus .card-banner-alt-button {
    outline: 2px solid ${({ colorProfile }) => themeUtil(Prop.LINK_TXT_HOVER, CPNT, colorProfile)};
    outline-offset: 3px;
  }
`;

const MainButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ colorProfile }) => themeUtil(Prop.LINK_TXT, CPNT, colorProfile)};
  ${() => getFontStyles(FontFamily.SECONDARY_FONT, undefined, undefined)};
  font-weight: bold;

  ${Breakpoints.desktopOnly(css`
    .link-icon {
      margin-left: 5px;
      svg {
        fill: ${({ colorProfile }) => themeUtil(Prop.LINK_TXT, CPNT, colorProfile)};
        color: ${({ colorProfile }) => themeUtil(Prop.LINK_TXT, CPNT, colorProfile)};
      }
    }
  `)}
`;

const Image = styled.img`
  display: block;
  max-width: 100%;
  width: 100%;
  height: 100%;
`;

export const CardBannerAlt = ({
  title, copy, image, colorProfile, blockType, ariaLabel,
  buttonEnabled, buttonText, buttonLink, buttonTarget, buttonClick,
  videoId, isStaticImageLink, alternateBlockLayoutState, formattedContent, inert,
}) => (
  <ContentBlockCardBannerAlt
    colorProfile={colorProfile}
    className={`content-block-${blockType} `}
    alternateBlockLayoutState={alternateBlockLayoutState}
  >
    <ImageContainer onClick={() => buttonClick()} type="button">
      {(image && !isStaticImageLink) && <GatsbyImage image={image.src} alt={image?.altText} />}
      {(image && isStaticImageLink) && <Image src={image.src} alt={image?.altText} />}
      {(videoId) && <Icon className="video-icon" name="content-play" width="100" height="100" />}
    </ImageContainer>
    <ContentContainer
      href={buttonLink}
      target={buttonTarget}
      onClick={() => buttonClick()}
      aria-label={ariaLabel}
      role="button"
      inert={inert}
    >
      {(title) && <h3 className="cb-title">{title}</h3>}
      {(copy) && <p className="cb-copy">{copy}</p>}
      {(formattedContent) && <WysiwygStyleWrapper className="cb-formatted-content">{ReactHtmlParser(formattedContent)}</WysiwygStyleWrapper>}
      {(buttonEnabled) && (
      <MainButton className="card-banner-alt-button">
        {buttonText}
        <Icon className="link-icon" name="ui-arrow-thin-right" width="24" height="14" />
      </MainButton>
      )}
    </ContentContainer>
  </ContentBlockCardBannerAlt>
);

CardBannerAlt.propTypes = PropTypes.objectOf(ContentBlockType).isRequired;
