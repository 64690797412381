import PropTypes from 'prop-types';
import React from 'react';

import { componentFactory } from '@powdr/utils';

import { StyledCollapsePanelGroup } from './styles';

export const CollapsePanelGroup = ({
  className,
  itemGap,
  parentColorProfile,
  relationships,
  children,
}) => {
  if ((!relationships || relationships?.length === 0) && !children) return null;

  return (
    <StyledCollapsePanelGroup
      className={className}
      $itemGap={itemGap || 15}
      $colorProfile={parentColorProfile}
    >
      {(relationships) && componentFactory(relationships?.components, parentColorProfile)}
      {(children) && children}
    </StyledCollapsePanelGroup>
  );
};

CollapsePanelGroup.propTypes = {
  className: PropTypes.string,
  itemGap: PropTypes.number,
  parentColorProfile: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  relationships: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  children: PropTypes.node,
};

CollapsePanelGroup.defaultProps = {
  className: '',
  itemGap: 15,
  parentColorProfile: null,
  children: null,
};
