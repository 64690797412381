import styled, { css } from 'styled-components';

import { Icon, Link } from '@powdr/components';
import { DatePicker } from '@powdr/components/widgets/datepicker';
import { Select } from '@powdr/components/widgets/select';
import {
  ThemeProps as Prop, Breakpoints, Components, FontFamily,
  FontSize,
} from '@powdr/constants';
import { getFontStyles, themeUtil } from '@powdr/utils';

const CPNT = Components.BOOKING_WIDGET;

export const StyledBookingWidget = styled.div`
  margin: ${({ $topPadding, $bottomPadding }) => `${$topPadding}px 0 ${$bottomPadding}px;`};

  ${Breakpoints.mobile(css`
    margin: ${({ $topPaddingMobile, $bottomPaddingMobile }) => `${$topPaddingMobile}px 0 ${$bottomPaddingMobile}px;`};
  `)}
`;

export const StyledTabField = styled.div`
  color: #55595c;
  position: relative;
  text-align: left;
  display: inline-block;
  margin-bottom: 15px;
  margin-right: 15px;
  width: 200px;
  min-height: 47.5px;

  ${Breakpoints.mobile(css`
    display: block;
    width: 100%;
  `)}

  &.w-300 {
    width: 300px;
  }
`;

export const StyledTabContent = styled.div`
  padding: 0 7.5%;
  text-align: center;
  display: flex;
  align-items: ${({ $isTopAlign }) => (($isTopAlign) ? 'flex-start' : 'flex-end')} ;
  justify-content: center;
  gap: 15px;

  ${Breakpoints.desktopOnly(css`
    ${({ show }) => (!show) && 'display: none;'};
  `)}

  ${Breakpoints.mobile(css`
    display: flex;
    padding: 15px 0;
    flex-direction: column;
  `)}
`;

export const StyledMobileIcon = styled(Icon)`

`;

const baseButtonStyles = css`
  color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT_INACTIVE, CPNT, colorProfile)};
  border: 1px solid ${({ colorProfile }) => themeUtil(Prop.BORDER, CPNT, colorProfile)};
  ${() => getFontStyles(FontFamily.SECONDARY_FONT)};
  cursor: pointer;
  display: inline-block;
  position: relative;
  text-align: center;
  line-height: normal;
  width: auto;
  height: auto;
  padding: 16px 20px;
  margin: 0 0 15px 0;
  max-height: 500px;

  &:hover:not(.disabled) {
    background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG_HOVER, CPNT, colorProfile)};
    color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT_HOVER, CPNT, colorProfile)};

    svg {
      fill: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT_HOVER, CPNT, colorProfile)};
      color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT_HOVER, CPNT, colorProfile)};
    }
  }

  &:focus, &.active {
    background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG_ACTIVE, CPNT, colorProfile)};
    color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT_ACTIVE, CPNT, colorProfile)};
  }

  &&:not(.active) {
    .mobile-icon {
      transition: .5s transform ease-in-out;
      transform: rotate(-90deg);
    }
  }

  &.disabled {
    cursor: not-allowed;
    background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG_INACTIVE, CPNT, colorProfile)};
    color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT_INACTIVE, CPNT, colorProfile)};
  }
`;

export const TabExecuteButton = styled(Link)`
  ${baseButtonStyles}
  margin: 0;
  height: 52px;
  width: 100%;
  max-width: 275px;
  background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG, CPNT, colorProfile)};
  border: none;
  color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT, CPNT, colorProfile)};

  ${Breakpoints.mobile(css`
    max-width: unset;
    width: 60%;
  `)}
`;

export const StyledTab = styled.li`
  ${baseButtonStyles}
  width: 100%;
  margin-bottom: 15px;

  &:not(.active) {
    color: ${({ colorProfile }) => themeUtil(Prop.CONTENT, CPNT, colorProfile)};

    svg {
      fill: ${({ colorProfile }) => themeUtil(Prop.CONTENT, CPNT, colorProfile)};
      color: ${({ colorProfile }) => themeUtil(Prop.CONTENT, CPNT, colorProfile)};
    }
  }

  ${Breakpoints.large(css`
    ${() => getFontStyles(FontFamily.SECONDARY_FONT, FontSize.REG40)};
  `)}

  ${Breakpoints.larger(css`
    ${() => getFontStyles(FontFamily.SECONDARY_FONT, FontSize.REG50)};
  `)}

  ${Breakpoints.mobile(css`
    text-align: left;
  `)}
`;

export const StyledDesktopTab = styled(StyledTab)`
  width: 100%;

  ${Breakpoints.mobile(css`
    display: none;
  `)}
`;

export const StyledMobileTab = styled(StyledTab)`
  display: none;
  justify-content: space-between;

  ${Breakpoints.mobile(css`
    ${({ isSingleTab }) => ((isSingleTab) ? 'display: none;' : 'display: flex;')}
    width: 100%;
  `)}
`;

export const StyledDesktopTabGroup = styled.ul`
  ${({ isSingleTab }) => ((isSingleTab) ? 'display: none;' : 'display: block;')}
  margin-bottom: 15px;

  ${({ isSingleTab, justified }) => justified && css`
    ${() => ((isSingleTab) ? 'display: none;' : 'display: flex;')}
    flex-wrap: nowrap;
    flex-flow: row;
    justify-content: space-between;
    gap: 15px;

    ${Breakpoints.mobile(css`
      flex-flow: column;
    `)}
  `}

  ${Breakpoints.mobile(css`
    display: none !important;
  `)}
`;

export const StyledSelect = styled(Select)`
  //${({ is5050 }) => ((is5050) ? 'width: 100%; max-width: none; margin-bottom: 10px;' : '')}
  margin-right: 0;
  margin-bottom: 0;

  ${Breakpoints.mobile(css`
    max-width: unset;
  `)}
`;

export const StyledDatePicker = styled(DatePicker)`
  //${({ is5050 }) => ((is5050) ? 'width: 100%; max-width: none; margin-bottom: 10px;' : '')}
  margin-right: 0;
  margin-bottom: 0;

  ${Breakpoints.mobile(css`
    max-width: unset;
  `)}
`;

export const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  width: 100%;
  max-width: 275px;

  ${Breakpoints.mobile(css`
    max-width: unset;
    width: 90%;
  `)}
`;

export const FieldLabel = styled.label``;

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 15px;

  ${({ is5050 }) => ((is5050) ? 'align-items: center; flex-direction: column;' : 'align-items: flex-end;')};

  ${Breakpoints.mobile(css`
    flex-direction: column;
    align-items: center;
  `)}
`;
