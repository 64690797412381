import axios from 'axios';

import { FetchStatus } from '@powdr/constants';
import { getNodeApiDomain } from '@powdr/utils';

import { SNOW_REPORTS_PENDING, SNOW_REPORTS_SUCCESS, SNOW_REPORTS_ERROR } from '../types';

export const getSnowReports = () => async (dispatch, getState) => {
  const snowReportStatus = getState().snowReports.status;

  // If more than one component calls the same feed make sure we only make one request
  if (snowReportStatus !== FetchStatus.LOADING && snowReportStatus !== FetchStatus.PENDING) {
    try {
      dispatch({
        type: SNOW_REPORTS_PENDING,
        payload: [],
      });
      const res = await axios.get(`${getNodeApiDomain()}/dor/drupal/snow-reports?sort=date&direction=desc`);
      dispatch({
        type: SNOW_REPORTS_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: SNOW_REPORTS_ERROR,
        payload: error,
      });
    }
  }
};
