import PropTypes from 'prop-types';
import React, {
  useEffect,
  useRef,
  useState,
  useContext,
  useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Loader } from '@powdr/components';
import {
  DorLiftsTrailsFilters,
  DorLiftsTrailsMain,
} from '@powdr/components/dor-lifts-trails/index';
import {
  LiftsTrails,
  LiftColumn,
  TrailColumn,
  SectorConditions,
  LiftConditions,
  DrawrGeneral,
  ContentFilter,
  StatusProps,
  FilterDefaultState,
  StatusTrailSubTypes,
  trailFilter,
  liftFilter,
  AbsolutePositions,
  ColorProfiles,
} from '@powdr/constants';
import { AppContext } from '@powdr/context';
import { getLiftTrailReport } from '@powdr/stores/actions/lift-trail-report-action';
import {
  convertCmsSetToBools,
  findStatusTrailSubType,
  getArrayIntersection,
  filterLiftTrailItemsBySeason,
} from '@powdr/utils';
import { useStaticData } from '@powdr/web/src/hooks';

import {
  StyledDorLiftsTrails,
  StyledLayoutWrapper,
  StyledDorLoaderWrapper,
  StyledDorErrorMessage,
} from './styles';

export const DorLiftsTrails = ({
  parentColorProfile,
  hideFilterContent,
  hideFilterDrawer,
  hideLiftsTrails,
  hideLiftColDesktop,
  hideTrailColDesktop,
  hideTrailColMobile,
  hideTrailFeature,
  hideTrailGroomedStatus,
  hideTrailDifficulty,
  hideSectorConditions,
  hideLiftColMobile,
  hideLiftsConditions,
  hideGeneralDrawr,
  hideTrailFeatureDiff,
  hideTrailFeatureSize,
  hideTrailStatus,
  hideLiftStatus,
  filterDefaultState,
  panelsDefaultState,
  overrideSeason,
  parentComponentSeason,
  filterToggleColorProfile,
  filterDrawerColorProfile,
  drawerPlacement,
  sectionMargin,
  liftIsExpandedOnLoad,
  numSectorsExpandedOnLoad,
}) => {
  const liftTrailContentRef = useRef();
  const {
    isMobile,
    secondLevelNavHeight,
    themeConfig,
  } = useContext(AppContext);

  const { property } = useStaticData();
  const settings = {
    property,
    isMobile,
    isDesktop: !isMobile,
    contentRef: liftTrailContentRef,
    hideLiftCol: isMobile ? hideLiftColMobile : hideLiftColDesktop,
    hideTrailCol: isMobile ? hideTrailColMobile : hideTrailColDesktop,
    liftColumn: convertCmsSetToBools(isMobile
      ? hideLiftColMobile
      : hideLiftColDesktop, LiftColumn),
    trailColumn: convertCmsSetToBools(isMobile
      ? hideTrailColMobile
      : hideTrailColDesktop, TrailColumn),
    sectorConditions: convertCmsSetToBools(hideSectorConditions, SectorConditions),
    filterContent: convertCmsSetToBools(hideFilterContent, ContentFilter),
    filterOutTrailTypes: findStatusTrailSubType(hideLiftsTrails, StatusTrailSubTypes),
    liftsTrails: convertCmsSetToBools(hideLiftsTrails, LiftsTrails),
    liftsConditions: convertCmsSetToBools(hideLiftsConditions, LiftConditions),
    generalDrawr: convertCmsSetToBools(hideGeneralDrawr, DrawrGeneral),
    season: overrideSeason || parentComponentSeason || StatusProps.SEASON.ALL_SEASONS,
    tableThStickyOffset: themeConfig.layout.headerHeight + secondLevelNavHeight + 70,
    drawerStickyOffset: themeConfig.layout.headerHeight + secondLevelNavHeight + 10,
    hideFilterDrawer,
    hideFilterContent,
    hideLiftsTrails,
    hideTrailFeature,
    hideTrailGroomedStatus,
    hideTrailDifficulty,
    hideSectorConditions,
    hideLiftsConditions,
    hideGeneralDrawr,
    hideTrailFeatureDiff,
    hideTrailFeatureSize,
    hideTrailStatus,
    hideLiftStatus,
    filterDefaultState,
    panelsDefaultState,
    overrideSeason,
    parentComponentSeason,
    filterToggleColorProfile,
    filterDrawerColorProfile,
    drawerPlacement,
    sectionMargin,
    liftIsExpandedOnLoad: liftIsExpandedOnLoad ?? true,
    numSectorsExpandedOnLoad: numSectorsExpandedOnLoad ?? 4,
  };

  const theme = {
    parentColorProfile,
    sectorLiftHeader: {
      class: 'conditions',
    },
    sectorTrailsHeader: {
      class: null,
    },
  };

  const dispatch = useDispatch();

  const useLiftTrailReportData = useSelector((state) => state.liftTrailReport);

  const {
    error: liftTrailReportError,
    data: liftTrailReportData,
  } = useLiftTrailReportData;

  const [data, setData] = useState({
    sectors: null,
    trails: null,
    lifts: null,
  });

  const [filtersLiftIds, setFiltersLiftIds] = useState([]);
  const [filtersSelectedLiftIds, setFiltersSelectedLiftIds] = useState([]);
  const [currentLiftFilters, setCurrentLiftFilters] = useState([]);

  const [filteredSectorTrailIds, setFilteredSectorTrailIds] = useState([]);
  const [filteredSectorLiftIds, setFilteredSectorLiftIds] = useState([]);
  const [filtersSelectedSectorLiftIds, setFiltersSelectedSectorLiftIds] = useState([]);
  const [filtersSelectedSectorTrailIds, setFiltersSelectedSectorTrailIds] = useState([]);
  const [currentSectorFilters, setCurrentSectorFilters] = useState([]);

  const activeCbTrails = (filters) => {
    if (!trailFilter) return null;
    return trailFilter.filter((grp) => !settings.hideFilterDrawer?.includes(grp.id)
      || !settings.hideFilterContent?.includes(grp.id)
      || !settings.hideTrailStatus?.includes(grp.id))
      .map((grp) => {
        const fields = grp.fields[settings?.property] || grp.fields.default;
        const selectedFields = fields?.filter((field) => filters
          ?.includes(field.id));
        return selectedFields?.length ? grp.id : null;
      })
      .filter(Boolean);
  };

  const activeCbLifts = (filters) => {
    if (!liftFilter) return null;
    return liftFilter.filter((grp) => !settings.hideFilterDrawer?.includes(grp.id)
      || !settings.hideFilterContent?.includes(grp.id)
      || !settings.hideLiftStatus?.includes(grp.id))
      .map((grp) => {
        const selectedFields = grp.fields?.filter((field) => filters
          ?.includes(field.id));
        return selectedFields?.length ? grp.id : null;
      })
      .filter(Boolean);
  };

  const getFilteredLiftData = (filters) => {
    const { lifts } = data;
    const filterGrps = activeCbLifts(filters);

    if (filterGrps.length === 0) return lifts.map((f) => f.id);

    const filterResults = (key) => lifts
      ?.filter((lift) => filters
        .includes(lift[key]?.id))
      .map((f) => f.id) || [];

    return filterResults('status');
  };

  const getFilteredTrailData = (filters) => {
    const { trails } = data;

    const filterGrps = activeCbTrails(filters);

    const filterResults = (key) => trails
      ?.filter((trail) => filters
        .includes(trail[key]?.id))
      .map((f) => f.id) || [];

    const statusResults = { id: 'status', ids: filterResults('status') };
    const difficultyResults = { id: 'difficulty', ids: filterResults('difficulty') };
    const groomedResults = { id: 'groomed', ids: filterResults('groomed') };

    const featureDifficultyResults = {
      id: 'featureDifficulty',
      ids: trails
        ?.filter((trail) => trail
          ?.featureDifficulty
          ?.some((f) => filters
            .includes(f.id)))
        ?.map((f) => f.id) || [],
    };

    const featureResults = {
      id: 'features',
      ids: trails?.filter((trail) => trail
        ?.feature?.some((f) => filters
          .includes(f?.id)))
        .map((f) => f?.id) || [],
    };

    const featureSizeResults = {
      id: 'featureSize',
      ids: trails
        ?.filter((trail) => trail
          ?.featureSize
          ?.some((f) => filters
            .includes(f.id)))
        .map((f) => f.id) || [],
    };

    const allFiltered = [
      statusResults,
      groomedResults,
      featureResults,
      difficultyResults,
      featureSizeResults,
      featureDifficultyResults,
    ].filter((arr) => filterGrps
      ?.includes(arr.id))
      ?.map((arr) => arr.ids);

    let intersection = [];

    if (filterGrps.length > 1) {
      intersection = getArrayIntersection(...allFiltered);
    } else {
      intersection = allFiltered.flat();
    }

    return intersection;
  };

  const handleOnCheckboxChange = (e, type) => {
    const { target } = e;
    const { value, checked: isChecked } = target;

    if (type === 'trail') {
      let checkedFilters = [];

      if (isChecked) {
        checkedFilters = [...currentSectorFilters, value];
      } else {
        checkedFilters = currentSectorFilters.filter((f) => f !== value);
      }

      const liftFilteredIds = getFilteredLiftData(checkedFilters);
      const trailFilteredIds = getFilteredTrailData(checkedFilters);

      setFilteredSectorTrailIds(trailFilteredIds);
      setFiltersSelectedSectorTrailIds(checkedFilters);

      setFilteredSectorLiftIds(liftFilteredIds);
      setFiltersSelectedSectorLiftIds(checkedFilters);

      setCurrentSectorFilters(checkedFilters);
    }

    if (type === 'lift') {
      let checkedFilters = [];

      if (isChecked) {
        checkedFilters = [...currentLiftFilters, value];
      } else {
        checkedFilters = currentLiftFilters.filter((f) => f !== value);
      }

      const liftFilteredIds = getFilteredLiftData(checkedFilters);

      setFiltersLiftIds(liftFilteredIds);
      setFiltersSelectedLiftIds(checkedFilters);
      setCurrentLiftFilters(checkedFilters);
    }
  };

  // ERRORS START
  if (liftTrailReportError) {
    const message = `DOR Lift Trail Report Error API Failed: ${liftTrailReportError?.message ?? ''}`;
    console.error(message);
    return <StyledDorErrorMessage message={message} />;
  }
  // ERRORS END

  useEffect(() => {
    dispatch(getLiftTrailReport());
  }, [dispatch]);

  useEffect(() => {
    if (liftTrailReportData) {
      // eslint-disable-next-line max-len
      const sectors = filterLiftTrailItemsBySeason(liftTrailReportData?.data?.sector, settings.season);

      setData({
        sectors,
        trails: liftTrailReportData?.data?.trail,
        lifts: liftTrailReportData?.data?.lift,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [liftTrailReportData]);

  const isShowLoader = useMemo(() => (!data.sectors || !data.trails || !data.lifts), [data]);

  return (
    <StyledDorLiftsTrails colorProfile={theme.parentColorProfile}>
      <StyledLayoutWrapper>
        <DorLiftsTrailsFilters
          data={data}
          settings={settings}
          theme={theme}
          onFilterChange={handleOnCheckboxChange}
        />
        {isShowLoader && (
          <StyledDorLoaderWrapper $show={isShowLoader}>
            <Loader
              className="icon"
              classAnimationEffect="loader-animated flip infinite"
              size="100"
            />
          </StyledDorLoaderWrapper>
        )}
        <DorLiftsTrailsMain
          show={!isShowLoader}
          ref={liftTrailContentRef}
          data={data}
          settings={settings}
          theme={theme}
          onFilterChange={handleOnCheckboxChange}
          filters={{
            sector: {
              trailIds: filteredSectorTrailIds,
              liftIds: filteredSectorLiftIds,
              selectedTrailIds: filtersSelectedSectorTrailIds,
              selectedLiftIds: filtersSelectedSectorLiftIds,
            },
            lift: {
              selectedIds: filtersSelectedLiftIds,
              ids: filtersLiftIds,
            },

          }}
        />

      </StyledLayoutWrapper>
    </StyledDorLiftsTrails>
  );
};

DorLiftsTrails.propTypes = {
  parentColorProfile: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  hideFilterContent: PropTypes.arrayOf(PropTypes.string),
  hideFilterDrawer: PropTypes.arrayOf(PropTypes.string),
  hideLiftsTrails: PropTypes.arrayOf(PropTypes.string),
  hideLiftColDesktop: PropTypes.arrayOf(PropTypes.string),
  hideTrailColDesktop: PropTypes.arrayOf(PropTypes.string),
  hideTrailColMobile: PropTypes.arrayOf(PropTypes.string),
  hideTrailFeature: PropTypes.arrayOf(PropTypes.string),
  hideTrailFeatureSize: PropTypes.arrayOf(PropTypes.string),
  hideTrailGroomedStatus: PropTypes.arrayOf(PropTypes.string),
  hideTrailDifficulty: PropTypes.arrayOf(PropTypes.string),
  hideSectorConditions: PropTypes.arrayOf(PropTypes.string),
  hideLiftColMobile: PropTypes.arrayOf(PropTypes.string),
  hideLiftsConditions: PropTypes.arrayOf(PropTypes.string),
  hideGeneralDrawr: PropTypes.arrayOf(PropTypes.string),
  hideTrailFeatureDiff: PropTypes.arrayOf(PropTypes.string),
  hideTrailStatus: PropTypes.arrayOf(PropTypes.string),
  hideLiftStatus: PropTypes.arrayOf(PropTypes.string),
  filterDefaultState: PropTypes.string,
  panelsDefaultState: PropTypes.string,
  overrideSeason: PropTypes.string,
  parentComponentSeason: PropTypes.string,
  filterToggleColorProfile: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  filterDrawerColorProfile: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  drawerPlacement: PropTypes.string,
  sectionMargin: PropTypes.string,
  liftIsExpandedOnLoad: PropTypes.bool,
  numSectorsExpandedOnLoad: PropTypes.number,
};

DorLiftsTrails.defaultProps = {
  parentColorProfile: null,
  hideFilterContent: [],
  hideFilterDrawer: [],
  hideLiftsTrails: [],
  hideLiftColDesktop: [],
  hideTrailColDesktop: [],
  hideTrailColMobile: [],
  hideTrailFeature: [],
  hideTrailFeatureSize: [],
  hideTrailGroomedStatus: [],
  hideTrailDifficulty: [],
  hideSectorConditions: [],
  hideLiftColMobile: [],
  hideLiftsConditions: [],
  hideGeneralDrawr: [],
  hideTrailFeatureDiff: [],
  hideTrailStatus: [],
  hideLiftStatus: [],
  filterDefaultState: FilterDefaultState.CLOSED,
  panelsDefaultState: FilterDefaultState.CLOSED,
  overrideSeason: null,
  parentComponentSeason: null,
  filterToggleColorProfile: ColorProfiles.BASE,
  filterDrawerColorProfile: ColorProfiles.PRIMARY,
  drawerPlacement: AbsolutePositions.LEFT,
  sectionMargin: null,
  liftIsExpandedOnLoad: true,
  numSectorsExpandedOnLoad: 4,
};
