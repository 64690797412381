import PropTypes from 'prop-types';
import React from 'react';

import {
  ComponentWrapper, StyledMobileTabDrawer, TabButton,
  InnerDrawerWrapper,
  StickyWrapper,
  PositionWrapper,
  ButtonText,
} from './styles';

export const MobileTabDrawer = ({
  className,
  sideOffset,
  stickyPosition,
  buttonText,
  drawerPosition,
  isDrawerOpen,
  handleDrawer,
  drawerColorProfile,
  buttonColorProfile,
  children,
}) => (
  <StyledMobileTabDrawer
    className={className}
    $position={drawerPosition}
    $isOpen={isDrawerOpen}
    $top={stickyPosition}
  >
    <PositionWrapper
      $position={drawerPosition}
      $isOpen={isDrawerOpen}
      $sideOffset={sideOffset}
    >
      <InnerDrawerWrapper
        $position={drawerPosition}
        $isOpen={isDrawerOpen}
      >
        <TabButton
          $colorProfile={buttonColorProfile}
          onClick={() => handleDrawer()}
        >
          <ButtonText $position={drawerPosition}>
            {buttonText}
          </ButtonText>
        </TabButton>
        <ComponentWrapper
          className="component-wrapper"
          $colorProfile={drawerColorProfile}
          $top={stickyPosition}
        >
          {children}
        </ComponentWrapper>
      </InnerDrawerWrapper>
    </PositionWrapper>
  </StyledMobileTabDrawer>
);

MobileTabDrawer.propTypes = {
  className: PropTypes.string,
};

MobileTabDrawer.defaultProps = {
  className: '',
};
