import PropTypes from 'prop-types';
import React from 'react';

import { ImageAttributionText, StyledImageAttribution } from './styles';

export const ImageAttribution = ({
  className,
  children,
  maxWidth,
}) => (
  <StyledImageAttribution
    className={className}
    $maxWidth={maxWidth}
  >
    <ImageAttributionText>
      {children}
    </ImageAttributionText>
  </StyledImageAttribution>
);

ImageAttribution.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  maxWidth: PropTypes.number,
};

ImageAttribution.defaultProps = {
  className: '',
  maxWidth: null,
};
