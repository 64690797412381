/* eslint-disable no-nested-ternary */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { Icon } from '@powdr/components/icon';
import { Link } from '@powdr/components/link';
import {
  BlockTypes, Breakpoints, FontFamily, FontSize, ThemeProps as Prop,
} from '@powdr/constants';
import { useWindowResize } from '@powdr/hooks';
import { ContentBlockType } from '@powdr/model';
import {
  themeUtil, getBackgroundImageSrc, rgbaTransparencyToHex, clamp,
  getFontStyles,
} from '@powdr/utils';

const CPNT = BlockTypes.FULL_3;
const colorSwitchArr = ['#00A1DF', '#FF1B42', '#0DD110', '#FFBE00'];

// styled components
const ContentBlockFull3 = styled(Link)`
  display: block;
  position: relative;
  width: 100%;
  height: 400px;
  background-image: ${({ bgImage }) => `url('${bgImage}')`};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  .cb-title {
    ${() => getFontStyles(FontFamily.PRIMARY_FONT, FontSize.LRG20, undefined, undefined)};
    margin-bottom: 15px;
  }

  .cb-copy {
    ${() => clamp(3)}
  }

  &:focus {
    outline: 2px solid ${({ colorProfile }) => themeUtil(Prop.LINK_TXT, CPNT, colorProfile)};

    .hover-layer {
      display: block;
    }

    .expand-icon {
      fill: ${({ colorProfile }) => themeUtil(Prop.ICON_HOVER, CPNT, colorProfile)};
      color: ${({ colorProfile }) => themeUtil(Prop.ICON_HOVER, CPNT, colorProfile)};
    }

    ${({ isUsingBrandingColors }) => ((isUsingBrandingColors) ? '.content-icon { svg { fill: white; color: white; } }' : '')}
  }

  .full-3-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;

    &:hover:not(.overlay){
      .hover-layer {
        display: block;
      }

      .expand-icon {
        fill: ${({ colorProfile }) => themeUtil(Prop.ICON_HOVER, CPNT, colorProfile)};
        color: ${({ colorProfile }) => themeUtil(Prop.ICON_HOVER, CPNT, colorProfile)};
      }

      ${({ isUsingBrandingColors }) => ((isUsingBrandingColors) ? '.content-icon { svg { fill: white; color: white; } }' : '')}
    }

    .content-icon {
      margin-bottom: 25px;

      svg {
        fill: ${({ colorProfile }) => themeUtil(Prop.CONTENT, CPNT, colorProfile)};
        color: ${({ colorProfile }) => themeUtil(Prop.CONTENT, CPNT, colorProfile)};
      }
    }

    .cb-content {
      text-align: left;
      width: 100%;
      padding: 0 75px 0 75px;
      background: transparent;
      position: relative;
      z-index: 1;

      .icon-c {
        justify-content: flex-start;
        position: relative;
        top: 0;
        left: 0;
        display: block;
        margin-left: 5px;
        margin-bottom: 30px;
      }

      .cb-title, .cb-copy {
        max-width: 100%;
      }

      p {
        margin-left: 2px;
        margin-bottom: 30px;
        line-height: 1.5rem;
      }

      .content-play-hollow {
        margin-top: 75px;
        margin-left: 0px;
        margin-bottom: 0px;

        svg {
          fill: ${({ colorProfile }) => themeUtil(Prop.CONTENT, CPNT, colorProfile)};
          color: ${({ colorProfile }) => themeUtil(Prop.CONTENT, CPNT, colorProfile)};
        }
      }

      .expand-icon {
        svg {
          fill: ${({ colorProfile }) => themeUtil(Prop.ICON, CPNT, colorProfile)};
          color: ${({ colorProfile }) => themeUtil(Prop.ICON, CPNT, colorProfile)};
        }
      }
    }
  }

  // breakpoints
  ${Breakpoints.larger(css`
    max-height: 500px;

    .content-play-hollow {
      margin-top: 125px;
    }
  `)}

  ${Breakpoints.large(css`
    max-height: 400px;

    .content-play-hollow {
      margin-top: 100px;
    }
  `)}

  ${Breakpoints.smallInbetween(css`
    max-height: 325px;

    .content-play-hollow {
      margin-top: 35px;
      width: 65px;
    }
  `)}

  ${Breakpoints.smaller(css`
    max-height: 325px;

    .content-play-hollow {
      margin-top: 35px;
      width: 65px;
    }
  `)}

  ${Breakpoints.mobile(css`
    .cb-content {
      padding: 0 45px;

      p {
        margin-bottom: 15px;
      }
    }

    .content-play-hollow {
      margin-top: 15px;
      width: 45px;
    }
  `)}
`;

const HoverLayer = styled.div`
  display: none;
  top: 0;
  left: 0;
  position: absolute;
  z-index: 1;
  height: 100%;
  width: 100%;
  ${({ isUsingBrandingColors, colorProfile, index }) => {
    if (isUsingBrandingColors) {
      return `background-color: ${colorSwitchArr[index] + rgbaTransparencyToHex(0.75)};`;
    }

    return `background-color: ${themeUtil(Prop.BACKGROUND, CPNT, colorProfile) + rgbaTransparencyToHex(0.75)};`;
  }}

`;

export const Full3 = ({
  index, title, copy, isOverlayEnabled, image, colorProfile, blockType, columnOption, ariaLabel,
  buttonLink, buttonTarget, buttonClick,
  iconName, videoId, expandImage, isUsingBrandingColors, isStaticImageLink, inert,
}) => {
  const [bgImage, setBgImage] = useState('');
  const resize = useWindowResize();

  useEffect(() => {
    if (resize.windowWidth) {
      setBgImage(
        (!isStaticImageLink)
          ? getBackgroundImageSrc(image?.src, resize.windowWidth, columnOption)
          : image.src,
      );
    }
  }, [resize.windowWidth]);

  const decorativeIconHeightCheck = () => ((!iconName.includes('decoration-')) ? '25' : ((iconName === 'decoration-hash-marks')) ? '50' : '');
  const decorativeIconWidthCheck = () => ((!iconName.includes('decoration-')) ? '25' : 'fit-content');

  return (
    <ContentBlockFull3
      href={buttonLink}
      role="button"
      target={buttonTarget}
      colorProfile={colorProfile}
      bgImage={bgImage}
      onClick={() => buttonClick()}
      aria-label={ariaLabel}
      isUsingBrandingColors={isUsingBrandingColors}
      className={`content-block-${blockType} `}
      inert={inert}
    >
      <div className={classNames('full-3-container', { overlay: isOverlayEnabled })}>
        <HoverLayer
          className="hover-layer"
          index={index}
          colorProfile={colorProfile}
          isUsingBrandingColors={isUsingBrandingColors}
        />
        <div className="cb-content">
          {(iconName) && (
            <Icon
              className="content-icon"
              name={iconName}
              height={decorativeIconHeightCheck()}
              width={decorativeIconWidthCheck()}
              color={(isUsingBrandingColors) ? colorSwitchArr[index] : null}
            />
          )}
          {(title) && <h3 className="cb-title">{title}</h3>}
          {(copy) && <p className="cb-copy">{copy}</p>}
          {(videoId) && <Icon name="content-play-hollow" width="75" height="75" />}
          {/* TODO: expand image functionality */}
          {(expandImage) && <Icon className="expand-icon" name="ui-expand" width="35" height="35" />}
        </div>
      </div>
    </ContentBlockFull3>
  );
};

Full3.propTypes = PropTypes.objectOf(ContentBlockType).isRequired;
