import PropTypes from 'prop-types';
import React from 'react';

import { MeasurementDisplayTypes, Symbols } from '@powdr/constants';
import { useCurrentTemperature } from '@powdr/hooks';
import { checkIfNullUndefined } from '@powdr/utils';

import { CircularGraph } from './circular-graph';
import { TextMeasurement } from './text-measurement';

export const CurrentTemp = ({
  measurementDisplayType,
  parentColorProfile,
  measurementLabel,
  measurement,
  icon,
}) => {
  const measurementData = useCurrentTemperature();

  if (!measurementData) return null;

  return (
    <>
      {(measurementDisplayType === MeasurementDisplayTypes.CIRCULAR_GRAPH) && (
        <CircularGraph
          colorProfile={parentColorProfile}
          label={measurementLabel}
        >
          {`${(!checkIfNullUndefined(measurementData?.value)) ? Math.round(measurementData.value) : Symbols.DOUBLE_DASH}${Symbols.DEGREE}`}
        </CircularGraph>
      )}
      {(measurementDisplayType === MeasurementDisplayTypes.TEXT
        || measurementDisplayType === MeasurementDisplayTypes.TEXT_LARGE) && (
        <TextMeasurement
          measurementDisplayType={measurementDisplayType}
          label={measurementLabel}
          icon={icon}
          measurement={measurement}
          colorProfile={parentColorProfile}
        >
          {`${(!checkIfNullUndefined(measurementData?.value)) ? Math.round(measurementData.value) : Symbols.DOUBLE_DASH}${Symbols.DEGREE}`}
        </TextMeasurement>
      )}
    </>
  );
};

CurrentTemp.propTypes = {
  parentColorProfile: PropTypes.string,
  measurementLabel: PropTypes.string,
  measurementDisplayType: PropTypes.string,
  measurement: PropTypes.string,
  icon: PropTypes.string,
};

CurrentTemp.defaultProps = {
  parentColorProfile: null,
  measurementLabel: null,
  measurementDisplayType: null,
  measurement: null,
  icon: null,
};
