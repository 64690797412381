import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import ReactHtmlParser from 'react-html-parser';

import { IconDetails } from '@powdr/components';
import { Components, ThemeProps, ColorProfiles } from '@powdr/constants';
import { themeUtil } from '@powdr/utils';

import {
  BlockTitle, StyledCampDetails, TitleBlock,
  Block,
  HalfBlock,
  CampText,
  CampTitle,
  CampSubtitle,
  LargeText,
  DetailsWrapper,
} from './styles';

export const CampDetails = ({
  className,
  title,
  additionalBlockTitle,
  additionalBlockCopy,
  agesCopy,
  weeksCopy,
  relationships,
}) => {
  const camps = relationships?.camps || [];
  const features = relationships?.features || [];
  const skillLevel = relationships?.skillLevel || [];

  return (
    <StyledCampDetails className={className}>
      <TitleBlock className="detail-1">{title}</TitleBlock>
      <HalfBlock className={classNames('detail-2', { 'missing-sibling': !camps?.[1] })}>
        <BlockTitle>Full Week</BlockTitle>
        <CampText>
          <CampTitle>{camps?.[0]?.title}</CampTitle>
          <CampSubtitle>
            {ReactHtmlParser(camps?.[0]?.subheader?.value)}
          </CampSubtitle>
        </CampText>
      </HalfBlock>
      {(camps?.[1]) && (
        <HalfBlock className={classNames('detail-3')}>
          <>
            <BlockTitle>Half Week</BlockTitle>
            <CampText>
              <CampTitle>{camps?.[1]?.title}</CampTitle>
              <CampSubtitle>
                {ReactHtmlParser(camps?.[1]?.subheader?.value)}
              </CampSubtitle>
            </CampText>
          </>
        </HalfBlock>
      )}
      <Block className="detail-4">
        <BlockTitle>Ages</BlockTitle>
        <LargeText>{ReactHtmlParser(agesCopy?.value)}</LargeText>
      </Block>
      <Block className="detail-5">
        <BlockTitle>Skill Level</BlockTitle>
        <IconDetails
          iconData={skillLevel}
          hyphenateItems
          iconColor={themeUtil(
            ThemeProps.HEADER,
            Components.CAMP_DETAILS,
            ColorProfiles.SECONDARY,
          )}
          textColor={themeUtil(
            ThemeProps.CONTENT,
            Components.CAMP_DETAILS,
            ColorProfiles.SECONDARY,
          )}
        />
      </Block>
      <Block className="detail-6">
        <BlockTitle>Weeks Offered</BlockTitle>
        <LargeText>
          {ReactHtmlParser(weeksCopy?.value)}
        </LargeText>
      </Block>
      <Block className="detail-7">
        <BlockTitle>Key Features</BlockTitle>
        <IconDetails
          iconData={features}
          iconColor={themeUtil(
            ThemeProps.HEADER,
            Components.CAMP_DETAILS,
            ColorProfiles.SECONDARY,
          )}
          textColor={themeUtil(
            ThemeProps.CONTENT,
            Components.CAMP_DETAILS,
            ColorProfiles.SECONDARY,
          )}
        />
      </Block>
      <Block className="detail-8">
        <BlockTitle>{additionalBlockTitle}</BlockTitle>
        <DetailsWrapper>
          {ReactHtmlParser(additionalBlockCopy?.value)}
        </DetailsWrapper>
      </Block>
    </StyledCampDetails>
  );
};

CampDetails.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  additionalBlockTitle: PropTypes.string,
  additionalBlockCopy: PropTypes.shape({
    value: PropTypes.string,
  }),
  agesCopy: PropTypes.string,
  weeksCopy: PropTypes.string,
  relationships: PropTypes.shape({
    camps: PropTypes.arrayOf(PropTypes.shape({})),
    features: PropTypes.arrayOf(PropTypes.shape({})),
    gender: PropTypes.arrayOf(PropTypes.shape({})),
    skillLevel: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

CampDetails.defaultProps = {
  className: '',
  title: null,
  additionalBlockTitle: null,
  additionalBlockCopy: null,
  agesCopy: null,
  weeksCopy: null,
  relationships: null,
};
