import styled from 'styled-components';

import { FontFamily, ThemeProps as Prop } from '@powdr/constants';
import { getFontStyles, themeUtil } from '@powdr/utils';

export const StyledImageAttribution = styled.div`
  grid-area: 1/1;
  height: 100%;
  width: 100%;
  ${({ $maxWidth }) => ($maxWidth) && `max-width: ${$maxWidth}`};
  z-index: 3;
  display: grid;
  grid-template-columns: auto auto;
  justify-content: flex-start;
  align-content: flex-end;
`;

export const ImageAttributionText = styled.p`
  margin: 15px;
  color: ${() => themeUtil(Prop.CONTENT)};
  ${() => getFontStyles(FontFamily.BASE_FONT)}
`;
