import styled, { css } from 'styled-components';

import { ThemeProps as Prop, Breakpoints, ColorProfiles } from '@powdr/constants';
import { themeUtil } from '@powdr/utils';

export const StyledUiForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: center;
`;
