import PropTypes from 'prop-types';
import React from 'react';

import {
  StyledFeatureItem, ItemAlignmentWrapper, ItemIcon, ItemText,
  InlineCopyIcon,
} from './styles';

export const FeatureItem = ({
  icon,
  iconColor,
  copyIcon,
  copyIconColor,
  text,
}) => (
  <StyledFeatureItem>
    <ItemAlignmentWrapper>
      <ItemIcon name={icon} iconColor={iconColor} width="20" height="20" />
      <ItemText>
        {text}
        {(copyIcon) && <InlineCopyIcon name={copyIcon} iconColor={copyIconColor} width="20" height="20" />}
      </ItemText>
      {/* {(copyIcon) && <ItemIcon name={copyIcon} iconColor={copyIconColor} width="20" height="20" />} */}
    </ItemAlignmentWrapper>
  </StyledFeatureItem>
);

FeatureItem.propTypes = {
  icon: PropTypes.string.isRequired,
  iconColor: PropTypes.string.isRequired,
  copyIcon: PropTypes.string,
  copyIconColor: PropTypes.string,
  text: PropTypes.string.isRequired,
};

FeatureItem.defaultProps = {
  copyIcon: null,
  copyIconColor: null,
};
