import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import styled, { css } from 'styled-components';

import { Icon } from '@powdr/components/icon';
import { Link } from '@powdr/components/link';
import {
  BlockTypes, Breakpoints, FontFamily, FontSize, ThemeProps as Prop,
} from '@powdr/constants';
import { ContentBlockType } from '@powdr/model';
import {
  getDateInfo, themeUtil, rgbaTransparencyToHex, getFontStyles,
} from '@powdr/utils';

const CPNT = BlockTypes.PRICE;

// styled components
const ContentBlockPrice = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  box-shadow: 0px 0px 5px -1px rgba(0,0,0,0.65);
  position: relative;
  width: 100%;
  overflow: hidden;
  min-height: 180px;
  padding: 2px;

  .cb-title {
    margin-bottom: 15px;
  }

  &:focus-within {
    outline: 2px solid ${({ colorProfile }) => themeUtil(Prop.LINK_TXT, CPNT, colorProfile)};
    outline-offset: 4px;
  }
`;

const PriceButton = styled(Link)`
  width: 100%;

  &:focus .button {
    color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT_HOVER, CPNT, colorProfile)};
    background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG_HOVER, CPNT, colorProfile)};
  }

  .cb-content {
    background-color: ${({ colorProfile }) => themeUtil(Prop.BACKGROUND, CPNT, colorProfile)};
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    width: 100%;

    .cb-copy {
      margin-bottom: 15px;
    }

    &.cb-sold-out:after {
      content: '';
      cursor: not-allowed;
      background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG_ACTIVE, CPNT, colorProfile) + rgbaTransparencyToHex(0.8)};
      z-index: 1;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;;
      display: block;
    }

    .c-nearly-sold-out {
      display: inline-block;
      color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT, CPNT, colorProfile)};
      background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG, CPNT, colorProfile)};
      border-radius: 30px;
      padding: 6px 12px;
      text-transform: ;
    }

    strong {
      text-transform: ;
    }

    .row.cta {
      z-index: 2;
    }

    .row {
      text-align: left;
      width: 100%;
      background: transparent;
      position: relative;
      z-index: 1;

      ${Breakpoints.mobile(css`
        padding: 15px;
      `)}

      &.details {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        min-height: 180px;
      }

      .button {
        width: 100%;
        text-align: center;
      }
    }
  }
`;

const PriceText = styled.h4`
  ${() => getFontStyles(FontFamily.PRIMARY_FONT, FontSize.MED40, undefined, undefined)};
`;

const PriceBanner = styled(Link)`
  position: absolute;
  top: 2px;
  right: 2px;
  display: block;
  z-index: 1;

  &:after {
    content: '';
    position: absolute;
    display: block;
    z-index: 1;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 80px 80px 0;
    border-bottom-color: transparent;
    border-right-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG, CPNT, colorProfile)};
  }

  .icon {
    z-index: 2;
    position: relative;
    padding: 10px;
  }

  .banner-icon {
    svg {
      fill: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT, CPNT, colorProfile)};
      color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT, CPNT, colorProfile)};
    }
  }

  &:focus {
    outline: 2px solid ${({ colorProfile }) => themeUtil(Prop.BTN_BG, CPNT, colorProfile)};
    outline-offset: 2px;
  }
`;

export const Price = ({
  title, copy, isOverlayEnabled, colorProfile, blockType, ariaLabel,
  buttonEnabled, buttonText, buttonLink, buttonTarget, buttonClick,
  inventoryState, date, endDate, iconName, iconLink,
  priceHeader, price, inert,
}) => {
  const isSoldOut = (inventoryState === 'soldout');
  const isNearlySoldOut = (inventoryState === 'nearly_soldout');
  const dateInfo = (date) ? getDateInfo(date, endDate, blockType) : null;

  return (
    <ContentBlockPrice
      className={`content-block-${blockType} `}
      colorProfile={colorProfile}
    >
      <PriceButton
        className="cb-btn"
        href={(isSoldOut) ? '#' : buttonLink}
        target={buttonTarget}
        onClick={() => buttonClick()}
        aria-label={ariaLabel}
        role="button"
        inert={inert}
      >
        <div className={classNames('cb-content', { 'cb-sold-out': isSoldOut })}>
          <div className="row details">
            {(title) && (
              <h3 className="cb-title">
                {`${title}`}
                <br />
                {(dateInfo) && ReactHtmlParser(dateInfo.full)}
              </h3>
            )}
            {(copy) && <p className="cb-copy">{copy}</p>}
            {(priceHeader) && <strong className="c-subtext">{priceHeader}</strong>}
            {(price) && <PriceText>{`$${price}`}</PriceText>}
            {(isNearlySoldOut) && <span className="c-nearly-sold-out">Nearly Sold Out</span>}
          </div>
          <div className="row cta">
            {(buttonEnabled && buttonText && !isOverlayEnabled) && (
              <div className="button  with-background">
                {(!isSoldOut) ? buttonText : 'Sold Out'}
              </div>
            )}
          </div>
        </div>
      </PriceButton>
      {(iconName) && (
        <PriceBanner colorProfile={colorProfile} href={iconLink}>
          <div className="row icon">
            <Icon className="banner-icon" name={iconName} width="25" height="25" />
          </div>
        </PriceBanner>
      )}
    </ContentBlockPrice>
  );
};

Price.propTypes = PropTypes.objectOf(ContentBlockType).isRequired;
