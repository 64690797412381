import styled from 'styled-components';

import {
  ThemeProps as Prop, AbsolutePositions, Components,
  FontFamily,
  FontSize,
} from '@powdr/constants';
import { getFontStyles, themeUtil } from '@powdr/utils';

const CPNT = Components.MOBILE_TAB_DRAWER;

const drawerWidth = 300;
const buttonWidth = 60;

const getTransformStyles = (slideInFrom, offset) => (
  (slideInFrom === AbsolutePositions.LEFT) ? {
    open: `
      ${slideInFrom}: 0;
      transform: translateX(-${offset}vw);
    `,
    closed: `
      ${slideInFrom}: 0;
      transform: translateX(calc(-${offset}vw - ${drawerWidth}px));
    `,
  } : {
    open: `
      ${slideInFrom}: 0;
      transform: translateX(${offset}vw);
    `,
    closed: `
      ${slideInFrom}: 0;
      transform: translateX(calc(${drawerWidth}px + ${offset}vw));
    `,
  }
);

export const StyledMobileTabDrawer = styled.div`
  z-index: 70;
  position: sticky;
  top: ${({ $top }) => `${$top || 150}px`};
  height: 0;
`;

export const PositionWrapper = styled.div`
  position: absolute;
  width: 360px;
  z-index: 70;
  transition: all 0.5s ease-in-out;

  ${({ $isOpen, $position, $sideOffset }) => ($isOpen ? `
    ${getTransformStyles($position, $sideOffset).open};
  ` : `
    ${getTransformStyles($position, $sideOffset).closed};
  `)}
`;

export const InnerDrawerWrapper = styled.div`
  display: grid;

  ${({ $position }) => (
    $position === AbsolutePositions.LEFT
      ? `
        grid-template-columns: auto 60px;
        grid-template-areas: \'component button\';
        `
      : `
        grid-template-columns: 60px auto;
        grid-template-areas: \'button component\';
        `)};
  grid-template-rows: 100%;
  position: relative;
`;

export const TabButton = styled.button`
  grid-area: button;
  background-color: ${({ $colorProfile }) => themeUtil(Prop.BTN_BG, CPNT, $colorProfile)};
  color: ${({ $colorProfile }) => themeUtil(Prop.BTN_TXT, CPNT, $colorProfile)};
  padding: 15px;
  ${() => getFontStyles(FontFamily.SECONDARY_FONT, FontSize.MED30, undefined, undefined)};
  z-index: 70;
  height: fit-content;
  width: ${buttonWidth + 1}px;

  &:hover {
    color: ${({ $colorProfile }) => themeUtil(Prop.BTN_TXT_HOVER, CPNT, $colorProfile)} ;
    background-color: ${({ $colorProfile }) => themeUtil(Prop.BTN_BG_HOVER, CPNT, $colorProfile)};
  }

  &:active {
    color: ${({ $colorProfile }) => themeUtil(Prop.BTN_TXT_ACTIVE, CPNT, $colorProfile)};
    background-color: ${({ $colorProfile }) => themeUtil(Prop.BTN_BG_ACTIVE, CPNT, $colorProfile)};
  }
`;

export const ButtonText = styled.p`
  writing-mode: vertical-lr;
  transform: rotate(${({ $position }) => (($position === AbsolutePositions.RIGHT) ? '-180deg' : '0deg')});
`;

export const ComponentWrapper = styled.div`
  grid-area: component;
  width: 300px;
  height: ${({ $top }) => `calc(100vh - ${$top || 150}px - 100px)`};
  overflow-y: scroll;
  background-color: ${({ $colorProfile }) => themeUtil(Prop.BACKGROUND, CPNT, $colorProfile)};
`;
