import classNames from 'classnames';
import { GatsbyImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import styled, { css } from 'styled-components';

import { WysiwygStyleWrapper } from '@powdr/components';
import { Icon } from '@powdr/components/icon';
import { Link } from '@powdr/components/link';
import {
  AlternatingBlockLayoutOptions, BlockTypes, Breakpoints, ThemeProps as Prop,
} from '@powdr/constants';
import { ContentBlockType } from '@powdr/model';
import { themeUtil, clamp } from '@powdr/utils';

const CPNT = BlockTypes.CARD_BANNER;

// styled components
const ContentBlockCardBanner = styled(Link)`
  display: flex;
  flex-direction: ${({ alternateBlockLayoutState }) => (
    (alternateBlockLayoutState === AlternatingBlockLayoutOptions.DEFAULT)
      ? 'row'
      : 'row-reverse'
  )};
  justify-content: center;
  transition: transform .5s;
  box-shadow: 0 2px 3px rgba(41, 45, 49, 0.1), 0 0 0 1px rgba(41, 45, 49, 0.1);
  margin: 5px;
  position: relative;
  background-color: ${({ colorProfile }) => themeUtil(Prop.BACKGROUND, CPNT, colorProfile)};

  ${Breakpoints.mobile(css`
    flex-direction: column-reverse;
  `)}

  &:before {
    content: '';
    transition: opacity 2s cubic-bezier(0.165, 0.84, 0.44, 1);
    box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.15);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: -1;
  }

  &:hover:not(.overlay), &:focus:not(.overlay) {
    transform: scale3d(1.006, 1.006, 1);

    &:after {
      opacity: 1;
    }
  }

  &:hover .button, &:focus .button {
    color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT_HOVER, CPNT, colorProfile)};
    background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG_HOVER, CPNT, colorProfile)};
  }

  &:focus .button {
    outline: 2px solid ${({ colorProfile }) => themeUtil(Prop.BTN_BG_HOVER, CPNT, colorProfile)};
    outline-offset: 2px;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 24px;
  width: 40%;
  position: relative;
  gap: 15px;

  .cb-title {}

  .cb-copy {
    ${() => clamp(6)}
  }

  .button {
    display: inline-block;
    width: fit-content;
  }

  ${Breakpoints.mobile(css`
    width: 100%;
  `)}
`;

const ImageContainer = styled.div`
  width: 60%;
  height: 100%;

  ${Breakpoints.mobile(css`
    width: 100%;
    padding: 0px;
  `)}

  .video-enabled {
    display: inline-block;
    position: absolute;
    top: calc(50% - 50px);
    left: calc(50% - 50px);
    fill: ${({ colorProfile }) => themeUtil(Prop.ICON, CPNT, colorProfile)};
    color: ${({ colorProfile }) => themeUtil(Prop.ICON, CPNT, colorProfile)};
  }

  &:hover {
    .video-enabled {
      fill: ${({ colorProfile }) => themeUtil(Prop.ICON_HOVER, CPNT, colorProfile)};
      color: ${({ colorProfile }) => themeUtil(Prop.ICON_HOVER, CPNT, colorProfile)};
    }
  }

  ${Breakpoints.mobile(css`
    min-height: auto;
    background: transparent;
  `)}
`;

const Image = styled.img`
  display: block;
  max-width: 100%;
  width: 100%;
`;

export const CardBanner = ({
  title, copy, isOverlayEnabled, image, colorProfile, blockType, ariaLabel,
  buttonEnabled, buttonText, buttonLink, buttonTarget, buttonClick,
  videoId, iconName, isStaticImageLink, alternateBlockLayoutState,
  formattedContent, inert,
}) => (
  <ContentBlockCardBanner
    href={buttonLink}
    target={buttonTarget}
    colorProfile={colorProfile}
    onClick={() => buttonClick()}
    aria-label={ariaLabel}
    role="button"
    className={classNames(`content-block-${blockType}`, { overlay: isOverlayEnabled })}
    alternateBlockLayoutState={alternateBlockLayoutState}
    inert={inert}
  >
    <ContentContainer>
      {(title) && <h3 className="cb-title">{title}</h3>}
      {(copy) && <p className="cb-copy">{copy}</p>}
      {(formattedContent) && <WysiwygStyleWrapper className="cb-formatted-content">{ReactHtmlParser(formattedContent)}</WysiwygStyleWrapper>}
      {(buttonEnabled && buttonText) && (
        <div className="button  with-background">{buttonText}</div>
      )}
      {(iconName) && <Icon className="cb-icon" name={iconName} width="30" height="30" />}
    </ContentContainer>
    <ImageContainer>
      {(image && !isStaticImageLink) && <GatsbyImage image={image.src} alt={image?.altText} />}
      {(image && isStaticImageLink) && <Image src={image.src} alt={image?.altText} />}
      {(videoId) && <Icon className="video-enabled" name="content-play" width="100" height="100" />}
    </ImageContainer>
  </ContentBlockCardBanner>
);

CardBanner.propTypes = PropTypes.objectOf(ContentBlockType).isRequired;
