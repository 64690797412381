import PropTypes from 'prop-types';
import React from 'react';

import { UiTable, UiHtml } from '@powdr/components';
import { componentFactory } from '@powdr/utils';

export const GlobalTable = ({
  parentColorProfile,
  tables,
  html,
  relationships,
}) => (
  <>
    {(relationships && relationships?.components?.length > 0) && componentFactory(relationships?.components, parentColorProfile)}
    {(tables) && (
      <UiTable
        tables={[tables]}
        parentColorProfile={parentColorProfile}
      />
    )}
    {(html) && (
      <UiHtml
        html={html}
        parentColorProfile={parentColorProfile}
      />
    )}
  </>
);

GlobalTable.propTypes = {
  parentColorProfile: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
  tables: PropTypes.shape(),
  html: PropTypes.shape(),
  relationships: PropTypes.shape({
    components: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

GlobalTable.defaultProps = {
  parentColorProfile: null,
  tables: null,
  html: null,
  relationships: null,
};
