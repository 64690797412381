/* eslint-disable no-nested-ternary */
import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getWeather } from '@powdr/stores/actions/weather-action';

// eslint-disable-next-line import/no-dynamic-require
const { gatsbyConfig } = require(`@powdr/${process.env.GATSBY_PROPERTY_NAME}`);

export const useCurrentTemperature = (refreshTimeMinutes) => {
  const { dor } = gatsbyConfig || {};
  const dispatch = useDispatch();
  const useWeatherData = useSelector((state) => state.weather);
  const { isLoading: weatherIsLoading, error: weatherError, data: weatherData } = useWeatherData;
  const [temperatureData, setTemperatureData] = useState();
  const refreshRef = useRef();

  // if refreshTimeMinutes is provded, get an updated weather value on an interval
  useEffect(() => {
    if (refreshTimeMinutes) {
      refreshRef.current = setInterval(() => {
        dispatch(getWeather());
      }, refreshTimeMinutes * 60000);
    }

    return () => {
      clearInterval(refreshRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(getWeather());
  }, [dispatch]);

  useEffect(() => {
    if (!weatherIsLoading) {
      setTemperatureData({
        icon: weatherData?.current?.weather_icon || null,
        value: weatherData?.current?.temperature || null,
        errors: {
          weather: weatherError,
        },
      });
    }
  }, [dor, weatherData, weatherError, weatherIsLoading]);

  return temperatureData;
};
