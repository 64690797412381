import PropTypes from 'prop-types';
import React from 'react';

import {
  MeasurementDisplayTypes, SnowReportUnits, SnowReportUnitsDisplay, Symbols,
} from '@powdr/constants';
import { useSnowReportMeasurements } from '@powdr/hooks';
import { getSnowfallMeasurementUnit } from '@powdr/utils';

import { CircularGraph } from './circular-graph';
import {
  ItemText,
  ItemUnit,
  ItemValue,
} from './styles';
import { TextMeasurement } from './text-measurement';

export const Snowfall = ({
  measurementDisplayType,
  snowReportMeasurement,
  parentColorProfile,
  measurementLabel,
  icon,
}) => {
  const measurementData = useSnowReportMeasurements();
  const measurementUnit = getSnowfallMeasurementUnit();
  const getSnowfallValue = (v) => (
    (measurementUnit !== SnowReportUnitsDisplay[SnowReportUnits.CENTIMETERS]) ? (
      <ItemValue>
        {`${(v === null) ? Symbols.DOUBLE_DASH : v}${measurementUnit}`}
      </ItemValue>
    ) : (
      <ItemText className="alt-snowfall-unit">
        <ItemValue>{(v === null) ? Symbols.DOUBLE_DASH : v}</ItemValue>
        <ItemUnit>
          {measurementUnit}
        </ItemUnit>
      </ItemText>
    )
  );

  if (!measurementData) return null;

  return (
    <>
      {(measurementDisplayType === MeasurementDisplayTypes.CIRCULAR_GRAPH) && (
        <CircularGraph
          colorProfile={parentColorProfile}
          label={measurementLabel}
        >
          {getSnowfallValue(measurementData[snowReportMeasurement])}
        </CircularGraph>
      )}
      {(measurementDisplayType === MeasurementDisplayTypes.TEXT
        || measurementDisplayType === MeasurementDisplayTypes.TEXT_LARGE) && (
        <TextMeasurement
          measurementDisplayType={measurementDisplayType}
          label={measurementLabel}
          icon={icon}
          colorProfile={parentColorProfile}
        >
          {getSnowfallValue(measurementData[snowReportMeasurement])}
        </TextMeasurement>
      )}
    </>
  );
};

Snowfall.propTypes = {
  parentColorProfile: PropTypes.string,
  measurementLabel: PropTypes.string,
  measurementDisplayType: PropTypes.string,
  snowReportMeasurement: PropTypes.string,
  icon: PropTypes.string,
};

Snowfall.defaultProps = {
  parentColorProfile: null,
  measurementLabel: null,
  measurementDisplayType: null,
  snowReportMeasurement: null,
  icon: null,
};
