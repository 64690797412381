// eslint-disable-next-line import/no-unresolved
import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import { Components, VerticalPosition } from '@powdr/constants';
import { AppContext } from '@powdr/context';
import { componentFactory } from '@powdr/utils';

import { StyledUiColumn } from './styles';

// list of components that will be forced to appear first on mobile regardless
// of CMS ordering in UI Column
const mobileTopOnlyComponents = [
  Components.SLIDER,
  Components.UI_IMAGE,
];

export const UiColumn = ({
  colorProfile,
  parentColorProfile,
  isUsingBrandingColors,
  isNoPadding,
  isAlternatePadding,
  columnGapPercentage,
  firstComponentPercentage,
  contentPosition,
  ignoreMobileLayoutRules,
  isParentNavFeed,
  navFeedLink,
  relationships,
  isHideComponent,
}) => {
  const { isMobile } = useContext(AppContext);

  if (
    !relationships?.columnComponents
    || relationships?.columnComponents?.length === 0
    || isHideComponent
  ) {
    return null;
  }

  const { columnComponents } = relationships;

  const getComponents = () => {
    if (!isMobile || ignoreMobileLayoutRules) return columnComponents;

    if (mobileTopOnlyComponents.includes(columnComponents?.[1]?.typeName)
      && !mobileTopOnlyComponents.includes(columnComponents?.[0]?.typeName)) {
      return columnComponents.reverse();
    }

    return columnComponents;
  };

  return (
    <StyledUiColumn
      contentPosition={contentPosition}
      isUsingBrandingColors={isUsingBrandingColors}
      isNoPadding={isNoPadding}
      isAlternatePadding={isAlternatePadding}
      $columnGap={columnGapPercentage || 3}
      $isParentNavFeed={isParentNavFeed}
      $firstComponentPercentage={firstComponentPercentage || 50}
    >
      {(componentFactory(
        getComponents(),
        colorProfile || parentColorProfile,
        undefined,
        {
          is5050: true, isParentNavFeed, navFeedLink, isInColumn: true,
        },
      ))}
    </StyledUiColumn>
  );
};

UiColumn.propTypes = {
  colorProfile: PropTypes.string,
  parentColorProfile: PropTypes.string,
  isUsingBrandingColors: PropTypes.bool,
  isNoPadding: PropTypes.bool,
  isAlternatePadding: PropTypes.bool,
  columnGapPercentage: PropTypes.number,
  firstComponentPercentage: PropTypes.number,
  contentPosition: PropTypes.string,
  ignoreMobileLayoutRules: PropTypes.bool,
  isParentNavFeed: PropTypes.bool,
  navFeedLink: PropTypes.shape(),
  relationships: PropTypes.shape().isRequired,
  isHideComponent: PropTypes.bool,
};

UiColumn.defaultProps = {
  colorProfile: null,
  parentColorProfile: null,
  isUsingBrandingColors: false,
  isNoPadding: false,
  isAlternatePadding: false,
  columnGapPercentage: 3,
  firstComponentPercentage: null,
  ignoreMobileLayoutRules: false,
  isParentNavFeed: false,
  navFeedLink: null,
  contentPosition: VerticalPosition.TOP,
  isHideComponent: false,
};
