import axios from 'axios';

import { FetchStatus } from '@powdr/constants';
import { getNodeApiDomain } from '@powdr/utils';

import { STATUS_PENDING, STATUS_SUCCESS, STATUS_ERROR } from '../types';

export const getStatus = () => async (dispatch, getState) => {
  const StatusStates = getState().status.status;

  // If more than one component calls the same feed make sure we only make one request
  if (StatusStates !== FetchStatus.LOADING && StatusStates !== FetchStatus.PENDING) {
    try {
      dispatch({
        type: STATUS_PENDING,
        payload: [],
      });
      const res = await axios.get(`${getNodeApiDomain()}/dor/status`);
      dispatch({
        type: STATUS_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: STATUS_ERROR,
        payload: error,
      });
    }
  }
};
