import styled, { css } from 'styled-components';

import { Link, Icon } from '@powdr/components';
import {
  ThemeProps as Prop, Breakpoints, FontSize, FontFamily,
  Components,
} from '@powdr/constants';
import { componentColor, getFontStyles } from '@powdr/utils';

const CPNT = Components.MAIN_NAV;

export const StyledInnerNavGroup = styled.nav`
  position: relative;
  display: grid;
  width: 100%;
  height: 100%;
`;

export const InnerNavList = styled.ul`
  grid-area: 1/1;
  height: 100%;
  width: 100%;
  background-color: ${() => componentColor(Prop.BACKGROUND, CPNT)};
`;

export const InnerNavItem = styled.li`
  text-align: left;
`;

const sharedItemStyles = css`
  display: flex;
  align-items: center;
  line-height: 1.1;
  margin-bottom: 15px;
  width: 100%;
  color: ${() => componentColor(Prop.CONTENT, CPNT, Prop.CONTENT)};
  text-align: left;
  ${() => getFontStyles(FontFamily.BASE_FONT, FontSize.REG40, undefined, undefined)};

  &:hover, &:focus {
    .inner-nav-item-icon {
      width: ${FontSize.REG40};
      margin-left: 5px;
      transition:
        width 200ms ease-in-out,
        margin-left 200ms ease-in-out;
    }

    &:focus {
      outline: 2px solid ${() => componentColor(Prop.CONTENT, CPNT, Prop.CONTENT)};
      outline-offset: 0.2em;
    }
  }
`;

export const InnerNavItemToggle = styled.button`
  ${sharedItemStyles}
`;

export const InnerNavItemLink = styled(Link)`
  ${sharedItemStyles}
`;

export const ToggleIcon = styled(Icon)`
  width: 0;
  height: ${FontSize.REG40};
  margin-left: 0;
  transition:
    width ${({ $animDuration }) => $animDuration}ms ease-in-out,
    margin-left ${({ $animDuration }) => $animDuration}ms ease-in-out;

  svg {
    fill: ${() => componentColor(Prop.CONTENT, CPNT, Prop.CONTENT)};
    color: ${() => componentColor(Prop.CONTENT, CPNT, Prop.CONTENT)};
  }
`;

export const ChildNav = styled.nav`
  grid-area: 1/1;
  width: 100%;
  height: 100%;
  background-color: white;
  transition: transform ${({ $animDuration }) => $animDuration}ms ease-in-out;

  ${({ $isOpen }) => ($isOpen ? `
    transform: translateX(0);
  ` : `
    transform: translateX(100%);
  `)}

  ${Breakpoints.mobile(css`
    display: none;
  `)}
`;

export const ChildNavWrapper = styled.div`
  transition: opacity ${({ $animDuration }) => $animDuration}ms ease-in-out;
  ${({ $isOpen }) => (($isOpen) ? 'opacity: 1;' : 'opacity: 0; pointer-events: none;')};
`;

export const ParentName = styled.button`
  display: flex;
  align-items: center;
  text-align: center;
  ${() => getFontStyles(FontFamily.SECONDARY_FONT, FontSize.MED10, undefined, undefined)};
  line-height: 1.1;
  width: 100%;
  color: ${() => componentColor(Prop.CONTENT, CPNT, Prop.CONTENT)};

  &:focus {
    outline: 2px solid ${() => componentColor(Prop.CONTENT, CPNT, Prop.CONTENT)};
    outline-offset: 0.2em;
  }

  .parent-name-icon {
    height: ${FontSize.MED10};
    margin-right: 8px;

    svg {
      fill: ${() => componentColor(Prop.CONTENT, CPNT, Prop.CONTENT)};
      color: ${() => componentColor(Prop.CONTENT, CPNT, Prop.CONTENT)};
    }
  }
`;

export const ChildNavList = styled.ul`
  padding-top: 15px;
`;

export const ChildNavItem = styled.li``;

export const ChildNavItemLink = styled(Link)`
  ${sharedItemStyles}
  ${() => getFontStyles(FontFamily.BASE_FONT, FontSize.REG40, undefined, undefined)};
`;
