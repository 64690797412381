import { GatsbyImage } from 'gatsby-plugin-image';
import styled, { css } from 'styled-components';

import {
  ThemeProps as Prop, Breakpoints, FontFamily, StaticProfiles,
} from '@powdr/constants';
import { getFontStyles, themeUtil } from '@powdr/utils';

export const StyledTvFlightManifest = styled.div`
  display: flex;
  gap: 15%;
  width: 100%;
  height: 100%;
  align-items: flex-start;
  justify-content: center;
`;

export const Manifest = styled.div`
  width: 100%;
  height: 100%;
`;

export const GroupName = styled.div`
  ${() => getFontStyles(FontFamily.PRIMARY_FONT, '40px')};
`;

export const GuideBlock = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
  padding: 15px 0;
  border-top: 2px solid ${() => themeUtil(Prop.BORDER, null, StaticProfiles.TV)};
  border-bottom: 2px solid ${() => themeUtil(Prop.BORDER, null, StaticProfiles.TV)};
`;

export const GuideTextBlock = styled.div``;

export const GuidePhoto = styled(GatsbyImage)`
  max-height: 200px;
  max-width: 200px;
  height: 100%;
  width: 100%;
`;

export const GuideTitle = styled.div`
  ${() => getFontStyles(FontFamily.PRIMARY_FONT, '20px')};
  margin-bottom: 10px;
`;

export const GuideName = styled.div`
  ${() => getFontStyles(FontFamily.PRIMARY_FONT, '30px')};
`;

export const NameList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

export const Name = styled.li`
  ${() => getFontStyles(FontFamily.PRIMARY_FONT, '30px')};
`;
