import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';

import { UiAccordionPanel } from '@powdr/components';
import {
  trailFilter,
  liftFilter,
} from '@powdr/constants';
import { filterLiftTrailItemsBySeason } from '@powdr/utils';

import { DorCheckboxGroup } from './dor-checkbox-group';
import { DorLegendTrailTypeRatingRender } from './dor-legend-trail-type-rating-render';
import { DorLiftTableRender } from './dor-lift-table-render';
import { DorLiftsDetailsRender } from './dor-lifts-details-render';
import {
  DataShape,
  FiltersShape,
  SettingsShape,
  ThemeShape,
} from './dor-prop-types';
import { DorTrailSectorRender } from './dor-trail-sector-render';
import {
  StyledDorSector,
  StyledLayoutSectors,
  StyledLegendTrailTypeRating,
  FilterNoResults,
} from './styles';

export const DorLiftsTrailsMain = forwardRef(({
  data, settings, theme, filters, onFilterChange, show,
}, ref) => {
  const [
    statusTrailCb,
    difficultyTrailCb,
    featureDifficultyTrailCb,
  ] = trailFilter;

  const [
    statusLiftCb,
  ] = liftFilter;

  const layoutTypes = {
    ROW: 'row',
    COLUMN: 'column',
    NONE: 'none',
  };

  const handleCheckboxClick = (updatedFilters, type) => {
    onFilterChange(updatedFilters, type);
  };

  return (
    <StyledLayoutSectors ref={ref} $show={show}>

      <StyledDorSector
        colorProfile={theme?.parentColorProfile}
        className={`sectors ${settings.isDesktop && 'sticky'}`}
        top={`${settings.drawerStickyOffset}`}
      >

        {settings.filterContent?.LIFT_STATUS && (

          <DorCheckboxGroup
            id="sector-status-lifts-legend"
            title="Lifts"
            inputLabelTitle="Lifts Status"
            className="lift-legend"
            checkInputsFields={statusLiftCb.fields
              .filter((f) => !settings.hideLiftStatus
                .includes(f.id))}
            onFiltersChange={(e) => handleCheckboxClick(e, 'lift')}
          />

        )}

        {settings?.liftsTrails?.LIFT && (

          <UiAccordionPanel
            id="sector-all-lifts-accordion"
            label="All Lifts"
            isOpen={settings.liftIsExpandedOnLoad}
          >

            <DorLiftsDetailsRender
              data={data}
              settings={settings}
              theme={theme}
            />

            {(filters.lift.ids.length > 0 || filters.lift.selectedIds?.length === 0)
              ? (
                <DorLiftTableRender
                  rows={
                    filterLiftTrailItemsBySeason(data?.lifts, settings?.season)
                      ?.filter((f) => (
                        filters.lift.ids?.length > 0
                          ? filters.lift.ids
                            ?.includes(f.id)
                          : filters.lift.selectedIds?.length === 0
                      ))
                  }
                  theme={theme}
                  settings={settings}
                  layoutTypes={layoutTypes.NONE}
                />
              ) : <FilterNoResults>No lifts results with given filters</FilterNoResults>}

          </UiAccordionPanel>

        )}

        {settings.filterContent?.STATUS && (

          <DorCheckboxGroup
            id="sector-status-trails-filters"
            title="Trails"
            className="trail-legend"
            inputLabelTitle="Trail Status"
            checkInputsFields={
              (statusTrailCb.fields[settings?.property]
                || statusTrailCb.fields?.default)
                ?.filter((f) => !settings.hideTrailStatus
                  .includes(f.id))
                }
            onFiltersChange={(e) => handleCheckboxClick(e, 'trail')}
          />

        )}

        {settings.filterContent?.FEATURE_DIFFICULTY && (

          <StyledLegendTrailTypeRating>

            <UiAccordionPanel
              id="sector-trail-types-rating-legend"
              label="Trail Types + Ratings"
              className="animated fadeInUp u-an-de-0"
            >

              <DorLegendTrailTypeRatingRender />

            </UiAccordionPanel>

          </StyledLegendTrailTypeRating>

        )}

        {(settings.filterContent?.FEATURE_DIFFICULTY || settings.filterContent?.DIFFICULTY) && (

          <UiAccordionPanel
            id="sector-trail-types-rating-filters"
            label="Filters For Trail Types + Ratings"
            className="animated fadeInUp u-an-de-0"
          >
            {settings.filterContent.FEATURE_DIFFICULTY && (

              <DorCheckboxGroup
                id={featureDifficultyTrailCb?.id}
                layout={layoutTypes.COLUMN}
                title="Filter By Trail Type"
                inputLabelTitle="Trail Type"
                checkInputsFields={(
                  featureDifficultyTrailCb.fields[settings?.property]
                  || featureDifficultyTrailCb.fields?.default
                )}
                onFiltersChange={(e) => handleCheckboxClick(e, 'trail')}
              />

            )}

            {settings.filterContent.DIFFICULTY && (

              <DorCheckboxGroup
                id={difficultyTrailCb?.id}
                layout={layoutTypes.COLUMN}
                title="Filter Trails by Difficulty Rating"
                inputLabelTitle="Difficulty Rating"
                checkInputsFields={(
                  difficultyTrailCb.fields[settings?.property]
                  || difficultyTrailCb.fields?.default
                )}
                onFiltersChange={(e) => handleCheckboxClick(e, 'trail')}
              />

            )}

          </UiAccordionPanel>
        )}

        <DorTrailSectorRender
          data={data}
          settings={settings}
          theme={theme}
          filters={filters}
        />

      </StyledDorSector>

    </StyledLayoutSectors>
  );
});

DorLiftsTrailsMain.propTypes = {
  data: DataShape.isRequired,
  settings: SettingsShape.isRequired,
  theme: ThemeShape.isRequired,
  filters: FiltersShape.isRequired,
  onFilterChange: PropTypes.func.isRequired,
};

DorLiftsTrailsMain.defaultProps = {};
