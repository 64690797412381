import styled, { css } from 'styled-components';

import {
  ThemeProps as Prop, Components,
  FontFamily,
  FontSize,
} from '@powdr/constants';
import { getFontStyles, themeUtil } from '@powdr/utils';

const CPNT = Components.PAGE_HEADER;

export const PageBackButton = styled.div`
  button {
    display: flex;
    text-transform: uppercase;
    flex-direction: row-reverse;
    align-items: center;
    margin-bottom: 20px;
    ${() => getFontStyles(FontFamily.SECONDARY_FONT, FontSize.REG40, undefined, undefined)};
    color: ${() => themeUtil(Prop.CONTENT, CPNT)};
    transition: color 0.15s ease-in;

    .levels-back-icon {
      margin-right: 5px;

      svg {
        transition: fill 0.15s ease-in;
        fill: ${() => themeUtil(Prop.CONTENT, CPNT)};
        color: ${() => themeUtil(Prop.CONTENT, CPNT)};
      }
    }

    &:hover, &:focus {
      color: ${() => themeUtil(Prop.LINK_TXT_HOVER, CPNT)};

      svg {
        fill: ${() => themeUtil(Prop.LINK_TXT_HOVER, CPNT)};
        color: ${() => themeUtil(Prop.LINK_TXT_HOVER, CPNT)};
      }
    }

    &:focus {
      outline: 2px solid ${() => themeUtil(Prop.LINK_TXT_HOVER, CPNT)};
      outline-offset: 2px;
    }
  }

  .level-back-icon {
    display: none;
  }
`;
