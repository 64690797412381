import styled, { css } from 'styled-components';

import { Icon } from '@powdr/components';
import {
  ThemeProps as Prop, StaticProfiles, FontFamily, FontSize,
} from '@powdr/constants';
import { getFontStyles, themeUtil } from '@powdr/utils';

const NavBarHeight = 85; // px

export const StyledTvMain = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto minmax(0, 1fr) ${NavBarHeight}px;
  grid-auto-rows: 1fr;
  gap: 55px 0px;
  grid-template-areas:
    "slide-title logo"
    "components components"
    "navigation navigation";
  width: 100%;
  height: 100%;
`;

const topRowPadding = 55;

export const SlideTitle = styled.div`
  grid-area: slide-title;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${topRowPadding}px 0 0 ${topRowPadding}px;
  color: ${() => themeUtil(Prop.HEADER, null, StaticProfiles.TV)};

  h1 {
    ${() => getFontStyles(FontFamily.PRIMARY_FONT, '80px')};
  }
`;

export const LogoWrapper = styled.div`
  grid-area: logo;
  padding: ${topRowPadding}px ${topRowPadding}px 0 150px;
  text-align: right;

  ${({ $isAltLogo }) => ($isAltLogo) && `
    display: flex;
    justify-content: flex-end;
  `}

  svg {
    max-width: 300px;
    max-height: 200px;
    height: 100%;
    width: auto;
  }
`;

export const AltIcon = styled(Icon)`
  max-width: 300px;
  max-height: 200px;
  height: 100%;
  width: auto;

  svg {
    color: ${() => themeUtil(Prop.ICON, null, StaticProfiles.TV)};
    fill: ${() => themeUtil(Prop.ICON, null, StaticProfiles.TV)}
  }
`;

export const ComponentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  grid-area: components;
  padding: 0 ${topRowPadding}px;
  width: 100%;
`;

export const TvNavBar = styled.div`
  display: flex;
  grid-area: navigation;
  padding-right: ${topRowPadding}px;
  justify-content: space-between;
  align-items: center;
  background-color: ${() => themeUtil(Prop.SIDE_NAV_ITEM_BG, null, StaticProfiles.TV)};
  color: ${() => themeUtil(Prop.SIDE_NAV_ITEM_TXT, null, StaticProfiles.TV)};
`;

export const NavBarInfoSection = styled.div`
  display: flex;
  align-items: center;
  gap: 25px;
  height: ${NavBarHeight}px;
`;

export const Clock = styled.div`
  ${() => getFontStyles(FontFamily.PRIMARY_FONT, FontSize.LRG50)};
`;

export const CurrentTempDisplay = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;
  height: 100%;
  gap: 10px;
`;

export const CurrentTempIcon = styled(Icon)`
  height: 100%;

  svg {
    color: ${() => themeUtil(Prop.SIDE_NAV_ITEM_TXT, null, StaticProfiles.TV)};
    fill: ${() => themeUtil(Prop.SIDE_NAV_ITEM_TXT, null, StaticProfiles.TV)};
  }
`;

export const CurrentTempValue = styled.div`
  ${() => getFontStyles(FontFamily.PRIMARY_FONT, FontSize.LRG50)};
`;

export const TvNav = styled.ul`
  display: flex;
  height: 100%;
`;

export const TvNavItem = styled.li`
  display: flex;
  align-items: center;
  padding-left: ${topRowPadding}px;
  padding-right: ${topRowPadding}px;
  ${({ $isActive }) => ($isActive) && `
    background-color: ${themeUtil(Prop.SIDE_NAV_ITEM_BG_ACTIVE, null, StaticProfiles.TV)};
  `};
`;

export const TvNavItemInner = styled.div`
  ${({ $isActive }) => ($isActive) && `
    color: ${themeUtil(Prop.SIDE_NAV_ITEM_TXT_ACTIVE, null, StaticProfiles.TV)};
  `};
  ${() => getFontStyles(FontFamily.PRIMARY_FONT, FontSize.LRG12)};
`;

export const TvNavItemIndex = styled.span`
  ${() => getFontStyles(FontFamily.PRIMARY_FONT, FontSize.MED30)};
`;

export const ButtonWrapper = styled.div`
  position: absolute;
  display: flex;
  gap: 25px;
`;

const SharedButtonStyles = css`
  height: 50px;
  padding: 10px;
  color: ${() => themeUtil(Prop.BACKGROUND, null, StaticProfiles.TV)};
  background-color: ${() => themeUtil(Prop.BACKGROUND, null, StaticProfiles.TV)};

  &:hover {
    background-color: ${() => themeUtil(Prop.SIDE_NAV_ITEM_BG, null, StaticProfiles.TV)};
    color: ${() => themeUtil(Prop.SIDE_NAV_ITEM_TXT_ACTIVE, null, StaticProfiles.TV)};
  }
`;

export const PauseButton = styled.button`
  ${SharedButtonStyles}
`;

export const NextButton = styled.button`
  ${SharedButtonStyles}
`;

export const PreviousButton = styled.button`
  ${SharedButtonStyles}
`;

export const AlertOverlay = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  gap: 50px;
`;

export const AlertMessageWrapper = styled.div`

`;

export const AlertMainMeesage = styled.div`
  color: ${() => themeUtil(Prop.HEADER, null, StaticProfiles.TV)};
  ${() => getFontStyles(FontFamily.PRIMARY_FONT, '120px')};
  margin-bottom: 25px;
`;

export const AlertSubMessage = styled.div`
  color: ${() => themeUtil(Prop.HEADER, null, StaticProfiles.TV)};
  ${() => getFontStyles(FontFamily.PRIMARY_FONT, '60px')};
`;

export const AlertIcon = styled(Icon)`
  svg {
    fill: ${({ $color }) => (($color) || themeUtil(Prop.ICON, null, StaticProfiles.TV))};
    color: ${({ $color }) => (($color) || themeUtil(Prop.ICON, null, StaticProfiles.TV))};
  }
`;
