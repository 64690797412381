/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import AnimateHeight from 'react-animate-height';

import { Icon } from '@powdr/components';
import { AppContext } from '@powdr/context';
import { useSeason } from '@powdr/hooks';
import { getLevelLink, getLevelName, isHideNavItem } from '@powdr/utils';

import { InnerNavGroup } from '../inner-nav-group';

import {
  StyledMainNavItem,
  Level1OnlyLink, Level1WithChildren,
  Level1ToggleWrapper,
  Level1Toggle, ToggleIcon,
} from './styles';

export const MainNavItem = ({
  className,
  itemIndex,
  isOpen,
  navData,
  itemToggleFunc,
  handleChildDrawer,
}) => {
  const animationDuration = 500; // milliseconds
  const { checkSeasonality } = useSeason();
  const { isMobile } = useContext(AppContext);
  const isTopRow = itemIndex < 4;

  const isLevel2Overrides = (page) => (
    page?.navRelationships?.navigationOverrides
    && page?.navRelationships?.navigationOverrides?.length > 0
  );

  const isLevel1Only = (page) => (!!(
    !page?.childPages
    || page?.childPages.length === 0
    || page?.childPages.filter(
      (childPage) => !isHideNavItem(childPage, checkSeasonality),
    ).length === 0
  ));

  return (
    <StyledMainNavItem
      className={className}
      tabIndex={0}
      aria-label={`${getLevelName(navData)} Navigation (Tab for children)`}
    >
      {(isLevel1Only(navData) && !isLevel2Overrides(navData)) ? (
        <Level1OnlyLink
          href={getLevelLink(navData)}
          ariaLabel={`Navigate to ${getLevelName(navData)} Page`}
          target={navData?.target}
        >
          {getLevelName(navData)}
          {(navData?.iconName) && <ToggleIcon name={navData?.iconName} height={25} $showIcon />}
        </Level1OnlyLink>
      ) : (
        <Level1WithChildren $isTopRow={isTopRow}>
          <Level1ToggleWrapper>
            <Level1Toggle
              onClick={() => itemToggleFunc(itemIndex)}
              $isOpen={isOpen}
            >
              <ToggleIcon
                name={(isMobile) ? 'ui-arrow-down' : 'ui-arrow-left'}
                height={20}
                $showIcon={isMobile}
                $isOpen={isOpen}
                $animDuration={animationDuration}
              />
              {getLevelName(navData)}
            </Level1Toggle>
          </Level1ToggleWrapper>
          <AnimateHeight
            duration={animationDuration}
            height={(isMobile) ? (isOpen) ? 'auto' : 0 : '100%'}
            animateOpacity={isTopRow}
            contentClassName="content-height"
          >
            <InnerNavGroup
              tabIndex="0"
              role="menuitem"
              aria-label="Menu Navigation Item"
              isOpen={isOpen}
              handleChildDrawer={handleChildDrawer}
              navData={(isLevel2Overrides(navData))
                ? navData.navRelationships.navigationOverrides
                : navData.childPages}
            />
          </AnimateHeight>
        </Level1WithChildren>
      )}
    </StyledMainNavItem>
  );
};

MainNavItem.propTypes = {
  navData: PropTypes.shape({
    id: PropTypes.string.isRequired,
    target: PropTypes.string,
    childPages: PropTypes.arrayOf(PropTypes.shape({})),
    navRelationships: PropTypes.shape({
      navigationOverrides: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }).isRequired,
  itemIndex: PropTypes.number.isRequired,
  className: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  itemToggleFunc: PropTypes.func.isRequired,
  handleChildDrawer: PropTypes.func.isRequired,
};

MainNavItem.defaultProps = {
  className: '',
};
