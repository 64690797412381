import styled from 'styled-components';

import {
  ThemeProps as Prop, FontFamily,
  Components,
} from '@powdr/constants';
import { getFontStyles, themeUtil } from '@powdr/utils';

const CPNT = Components.RATES_AND_DATES_TABS;

export const StyledRatesAndDatesTabs = styled.div``;

export const Tabs = styled.div`
  display: flex;
  gap: 50px;
  margin-bottom: 25px;
  overflow-x: auto;
`;

export const TabButton = styled.button`
  color: ${({ $colorProfile }) => themeUtil(Prop.CONTENT, CPNT, $colorProfile)};
  ${() => getFontStyles(FontFamily.SECONDARY_FONT, '26px')};
  ${({ $isActive }) => ($isActive) && ' text-decoration: underline;'};

`;

export const TabContentWrapper = styled.div``;
