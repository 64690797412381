import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { ColorProfiles } from '@powdr/constants';

import { RatesAndDatesGroup } from './components';
import {
  StyledRatesAndDatesTabs, TabButton, TabContentWrapper, Tabs,
} from './styles';

export const RatesAndDatesTabs = ({
  relationships,
}) => {
  const groups = relationships?.groups || [];
  const [activeTab, setActiveTab] = useState(0);

  if (!groups || groups.length === 0) return null;

  return (
    <StyledRatesAndDatesTabs>
      <Tabs>
        {(groups.length > 1) && groups.map((group, idx) => (
          <TabButton
            $isActive={idx === activeTab}
            onClick={() => setActiveTab(idx)}
            $colorProfile={ColorProfiles.BASE}
          >
            {group.title}
          </TabButton>
        ))}
      </Tabs>
      <TabContentWrapper>
        <RatesAndDatesGroup data={groups[activeTab]} />
      </TabContentWrapper>
    </StyledRatesAndDatesTabs>
  );
};

RatesAndDatesTabs.propTypes = {
  relationships: PropTypes.shape({
    groups: PropTypes.arrayOf({}),
  }).isRequired,
};

RatesAndDatesTabs.defaultProps = {};
