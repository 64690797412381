import axios from 'axios';

import { FetchStatus } from '@powdr/constants';
import { getNodeApiDomain } from '@powdr/utils';

import { TRAILS_PENDING, TRAILS_SUCCESS, TRAILS_ERROR } from '../types';

export const getTrails = () => async (dispatch, getState) => {
  const trailsStatus = getState().trails.status;

  // If more than one component calls the same feed make sure we only make one request
  if (trailsStatus !== FetchStatus.LOADING && trailsStatus !== FetchStatus.PENDING) {
    try {
      dispatch({
        type: TRAILS_PENDING,
        payload: [],
      });
      const res = await axios.get(`${getNodeApiDomain()}/dor/drupal/trails`);
      dispatch({
        type: TRAILS_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: TRAILS_ERROR,
        payload: error,
      });
    }
  }
};
